import { forecastData } from '@/stores/dn-forecast-data.js';
import { forecastAiData } from '@/stores/dn-forecast-ai-data.js';
import { simulationData } from "@/stores/dn-simulation-data.js";
import { specialDayData } from '@/stores/dn-special-day-data.js';
import { useDataStore } from "@/stores/dataStore.js";
import { useFileImportStore } from "@/stores/fileImportStore.js";
import { useForecastStore } from '@/stores/forecastStore.js';
import { usePortfolioStore } from "@/stores/portfolioStore.js";
import { useReportStore } from "@/stores/reportStore.js";
import { useScheduleStore } from '@/stores/scheduleStore.js';

export function resetStores() {
  forecastData.reset();
  specialDayData.reset();
  forecastAiData.reset();
  simulationData.reset();
  useDataStore().$reset();
  useFileImportStore().$reset();
  useForecastStore().$reset();
  usePortfolioStore().$reset();
  useReportStore().$reset();
  useScheduleStore().$reset();
}