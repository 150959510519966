import { defineStore } from 'pinia';
import { addDays } from "@/js/dn-helper.js";
import { getSimData, SimState } from '@/js/dn-simulation.js';
import { getEmployees } from '@/model/dn-employee';
import { createSchedule } from '@/model/dn-employee-schedule.js';
import { getScheduleTasks } from '@/js/dn-fetch-schedule.js';
import { useDataStore } from '@/stores/dataStore.js';
import { forecastData } from '@/stores/dn-forecast-data.js';
import { forecastAiData } from '@/stores/dn-forecast-ai-data.js';
import { specialDayData } from '@/stores/dn-special-day-data.js';
import { useForecastStore } from '@/stores/forecastStore.js';
import { useScheduleStore } from "@/stores/scheduleStore.js";

export const usePortfolioStore = defineStore('portfolio', {
  state: () => ({
    callCenterKeyFigures: [],
    callCenterKeyFiguresLoadingStatus: 0,
  }),
  actions: {
    clearCallCenterKeyFigures() {
      this.callCenterKeyFigures = [];
      this.callCenterKeyFiguresLoadingStatus = 0;
    },
    /**
     * @param {Date} startDate
     */
    async fetchCallcenterKeyFigures(startDate) {
      const dataStore = useDataStore();
      this.callCenterKeyFiguresLoadingStatus = 0;
      let callCenterKeyFifures = []
      /** @type {{avgQueue:boolean; datesWithChanges:any[]; employeeOccupancy:boolean; forceUpdate:boolean; numberOfSimIterations:number; occ:boolean; sl:boolean; waitWithin:boolean}} */
      const outputSpec = { forceUpdate: true, datesWithChanges: [], sl: true, occ: true, avgQueue: false, waitWithin: false, employeeOccupancy: false, numberOfSimIterations: 1 }

      const taskTypeMap = dataStore.taskTypeMap

      /** @type {{id:number}[]} */
      const activeCallCenters = dataStore.callCentersAllowed;

      for (let c = 0; c < activeCallCenters.length; c++) {
        let cc = activeCallCenters[c]

        let data = { occ: [], sl: [], work: [], vacation: [], sick: [], employees: [] }
        for (let d = 0; d < 7; d++) {
          const dateInterval = { numberOfDays: 1, st: addDays(startDate, d) }
          const scheduleOptions = { dateInterval: [addDays(startDate, d), addDays(startDate, d)], }
          const x = await getCCSimData(cc, dateInterval, outputSpec, scheduleOptions);
          data.occ.push(x.simdata.occ)
          data.sl.push(x.simdata.slAvg)
          data.work.push(x.simdata.work)
          data.vacation.push(x.vacation)
          data.sick.push(x.sick)
          data.employees.push(x.employees)
          this.callCenterKeyFiguresLoadingStatus += 100 / (activeCallCenters.length * 7)
        }
        callCenterKeyFifures.push(data)
      }

      this.callCenterKeyFigures = callCenterKeyFifures;

      /**
       * @param {{ id: number; }} cc
       * @param {{ st: Date; numberOfDays: number; }} dateInterval
       * @param {{ avgQueue: boolean; datesWithChanges: any[]; employeeOccupancy: boolean; forceUpdate: boolean; numberOfSimIterations: number; occ: boolean; sl: boolean; waitWithin: boolean; }} outputSpec
       * @param {{ dateInterval: Date[]; }} scheduleOptions
       */
      async function getCCSimData(cc, dateInterval, outputSpec, scheduleOptions) {
        const dataStore = useDataStore();
        const forecastStore = useForecastStore();
        const scheduleStore = useScheduleStore();
        const taskTypes = dataStore.taskTypes;
        return await new Promise((resolve) => {
          let promises = []
          promises.push(getEmployees(cc.id))
          promises.push(getScheduleTasks([dateInterval], cc.id))
          Promise.all(promises).then((values) => {
            /** @type {import("@/model/dn-employee.js").Employee[]} */ // @ts-ignore
            let employees = values[0]
            /** @type {import("@/model/dn-task.js").Task[]} */  // @ts-ignore
            let scheduleTasks = values[1]
            //calculate sick and vacation
            const schedule = createSchedule(1, dateInterval.st, scheduleTasks, taskTypes, employees, false);
            let numberOfSick = 0
            let numberOfVacation = 0
            for (let e = 0; e < schedule.length; e++) {
              if (schedule[e].absences.length > 0) {
                let absTask = schedule[e].absences[0]
                let tt = taskTypeMap.get(absTask.tasktypeId)
                if (tt.subtype == 's') { ++numberOfSick }
                if (tt.subtype == 'v') { ++numberOfVacation }
              }
            }
            const callGroups = dataStore.callGroups;
            const ccCallGroups = callGroups.filter((cg) => cg.contactCenterId == cc.id);
            const affinities = new Set();
            for (const cg of ccCallGroups) {
              affinities.add(cg.affinity);
            }

            let occ = 0;
            let slAvg = 0;
            let work = 0;
            for (const affinity of affinities) {
              const simState = new SimState(taskTypes, dataStore.dateNow, forecastStore.forecastAdjustmentmap,
                forecastStore.forecastOptions, forecastData.historicDataMap,forecastAiData.forecastDataMap, scheduleStore.simulationSettings,
                specialDayData.specialDaysMap, ccCallGroups, dataStore.latestImportDate, dataStore.employeeCallcenters, cc.id, affinity);
              const simData = getSimData(
                outputSpec,
                simState,
                scheduleOptions,
                schedule,
              );
              occ += simData.occ;
              slAvg += simData.serviceLevelDisplayData[0].slAvg;
              work += simData.work;
            }

            if (affinities.size > 0) {
              occ = occ / affinities.size;
              slAvg = slAvg / affinities.size;
            }

            let data = { simdata: { occ, slAvg, work }, sick: numberOfSick, vacation: numberOfVacation, employees: employees.length }
            resolve(data)
          })
        })
      }
    },
  }
});