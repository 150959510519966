/**
 * @param {Date} dt
 * @param {number} days
 */
export function addDays(dt, days) {
  const result = new Date(dt)
  result.setDate(dt.getDate() + days)
  return result;
}

/**
 * @param {any[]} array
 * @param {number} factor
 */
export function condence(array, factor, round = true) {
  let returnArray = []
  if (array) {
    for (let i = 0; i < array.length; i++) {
      let s = 0
      let nullCounter = 0
      for (let j = 0; j < factor; j++) {
        if (array[i] === null) { nullCounter += 1 }//if no calls sl is null
        else { s += array[i] }
        if (j > 0) { i++ }
      }
      if (nullCounter == factor) { returnArray.push(null) }
      else {
        if (round) { returnArray.push(Math.round(s / factor)) } else { returnArray.push(s / factor) }
      }
    }
  }
  return returnArray
}

/**
 *  convert string of format YYYY-MM-DD to Date with
 * @param {string} isoDate
 */
export function fromISODate(isoDate) {
  if (!isoDate)
    return null;
  return new Date(isoDate + 'T00:00:00');
}

/**
 * Chart resolution
 * @param {number} numberOfDays
 */
export function getDisplayResolutionFactor(numberOfDays) {
  let displayResolutionFactor = 1;
  if (numberOfDays <= 2) {
    displayResolutionFactor = 1;
  } else if (numberOfDays <= 7) {
    displayResolutionFactor = 2;
  } else if (numberOfDays <= 28) {
    displayResolutionFactor = 4;
  } else {
    displayResolutionFactor = 24;
  }
  return displayResolutionFactor;
}

/**
 * @param {Date} dt1
 * @param {Date} dt2
 */
export function getDayDiff(dt1, dt2) {
  const diffTime = Math.abs(dt2.getTime() - dt1.getTime());
  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

/**
 * @param {Date[]} intervalMinusOneDay
 */
export function getNumberOfDaysFromIntervalMinusOne(intervalMinusOneDay) {
  return 1 + getDayDiff(intervalMinusOneDay[1], intervalMinusOneDay[0])
}

/**
 * @param {Date} dt
 */
export function getElapsedTimeString(dt) {
  let elapsed = Math.floor((Date.now() - dt.getTime()) / 1000);
  if (elapsed < 1) { return 'now';}
  let unit = 'second';
  if (elapsed >= 60) {
    elapsed = Math.floor(elapsed / 60);
    unit = 'minute';
    if (elapsed >= 60) {
      elapsed = Math.floor(elapsed / 60);
      unit = 'hour';
      if (elapsed >= 24) {
        elapsed = Math.floor(elapsed / 24);
        unit = 'day';
      }
    }
  }

  if (elapsed > 1) {
    unit = unit + 's';
  }

  return `${elapsed} ${unit} ago`;
}

/**
 * @param {Date} dt
 * @param {number} empid
 */
export function getSelectionKey(dt, empid) {
  return getStartOfDay(dt).getTime() + '_' + empid;
}

/**
 * @param {Date} dt
 */
export function getShortDate(dt) {
  var mm = dt.getMonth() + 1; // getMonth() is zero-based
  var dd = dt.getDate();
  return [dt.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('-');
}

/**
 * @param {Date} dt
 */
export function getStartOfDay(dt) {
  var startOfDay = new Date(dt);
  startOfDay.setHours(0, 0, 0, 0);
  return startOfDay;
}

/**
 * @param {string | number | Date} dt
 * @param {number} weekStartDay
 */
export function getStartOfWeek(dt, weekStartDay = 0) {
  let d = new Date(dt);
  d = new Date(d.getFullYear(), d.getMonth(), d.getDate());
  let dayIndex = d.getDay() - weekStartDay;
  if (dayIndex < 0) { dayIndex += 7; }
  d.setDate(d.getDate() - dayIndex);
  return d;
}

/**
 * @param {Date} dt
 */
export function getQuarterHour(dt) {
  return dt.getHours() * 4 + Math.round(dt.getMinutes() / 15);
}

/**
 * @param {{id:number}[]} idObjList
 */
export function getUniqueNegativeId(idObjList) {
  let id = 0;
  for (const idObj of idObjList) {
    id = Math.min(id, idObj.id);
  }
  return id - 1;
}

/**
 * Checks if the second array is a subset of the first.
 * @param {any[]} sortedArray
 * @param {any[]} sortedArray2
 */
export function isSubset(sortedArray, sortedArray2) {
  if (sortedArray.length >= sortedArray2.length) {
    let i = 0;
    for (const value of sortedArray2) {
      while (i < sortedArray.length && sortedArray[i] != value) {
        i += 1;
      }
      if (i >= sortedArray.length) {
        return false;
      }
    }

    return true;
  }

  return false;
}

/**
 * @param {any[]} array
 */
export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

/**
 * Sorts all intervals in place.
 * @param {{st:Date; fi:Date}[]} dateIntervals
 */
export function sortDateIntervals(dateIntervals) {
  dateIntervals.sort((a, b) => {
    let diff = a.st.getTime() - b.st.getTime();
    if (diff === 0) {
      diff = b.fi.getTime() - a.fi.getTime();
    }
    return diff;
  });
}

/**
 * @param {{st:Date; fi:Date}[]} dateIntervals
 */
export function sumDateIntervals(dateIntervals) {
  sortDateIntervals(dateIntervals)
  let sum = 0;
  let lastTime = 0;
  for (const interval of dateIntervals) {
    let stMs = interval.st.getTime();
    let fiMs = interval.fi.getTime();
    if (lastTime > stMs) {
      stMs = lastTime;
    }
    if (stMs < fiMs) {
      sum += fiMs - stMs;
      lastTime = fiMs;
    }
  }
  return sum;
}

/**
 * convert local time to a string of format YYYY-MM-DD
 * @param {Date} date
 */
export function toISODate(date) {
  if (!date)
    return null;
  return date.toLocaleDateString('sv-SE', { year: 'numeric', month: 'numeric', day: 'numeric' });
}

  /**
 * @param {Intl.LocalesArgument} lang
 * @param {Array<Number>} dtRange
 * @param {Number} incrementPerQuarter //tested with 1 or 3 (15 or 5 min)
 */
  export function timeRangeInformation(lang, dtRange, incrementPerQuarter) {
    const st = dtRange[0];
    const fi = dtRange[1];
    if (st > 0 || fi < 96*incrementPerQuarter) {
      const dtFormat = new Intl.DateTimeFormat(lang, { hour: 'numeric', minute: '2-digit' });
      const zero = new Date(2000, 0, 1);
      const ticksPerInterval = 1000 * 60 * 15/incrementPerQuarter;
      const stDate = new Date(zero.getTime() + ticksPerInterval * st);
      const fiDate = new Date(zero.getTime() + ticksPerInterval * fi);
      return `${dtFormat.format(stDate)} - ${dtFormat.format(fiDate)}`;
    }
    return '';
  }