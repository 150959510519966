import { defineStore } from 'pinia';
import { shallowReactive } from 'vue';
import { useDataStore } from "@/stores/dataStore.js";
import { forecastData } from '@/stores/dn-forecast-data.js';
import { forecastAiData } from '@/stores/dn-forecast-ai-data.js';
import { specialDayData } from '@/stores/dn-special-day-data.js';
import { useForecastStore } from '@/stores/forecastStore.js';
import { Availabilities } from '@/model/dn-availability.js'
import { AvailabilityRequests } from '@/model/dn-availability-request';
import { getBreakOptimizationSettings } from '@/model/dn-break-optimization-settings';
import { EmployeeContext } from '@/model/dn-employee-context.js';
import { getAffintyIndicators } from "@/js/dn-employee-helper.js";
import { EmployeeList } from '@/model/dn-employee';
import { EmployeeScheduleList } from '@/model/dn-employee-schedule.js';
import { fetchAndCheckJson } from '@/js/dn-fetch.js';
import { getScheduleTasks, getScheduleTasksUpdated, updateScheduleTasks } from '@/js/dn-fetch-schedule.js';
import { addDays, getNumberOfDaysFromIntervalMinusOne } from "@/js/dn-helper.js";
import { getRotations } from '@/model/dn-rotation.js';
import { getShiftBags } from '@/model/dn-optimizable-shift.js';
import columnDefinitions from "@/jso/dn-column-definitions.json";
import columnDefinitionsDetailed from "@/jso/dn-column-definitions-detailed.json";
import { getCustomShifts } from '@/model/dn-custom-shift.js';
import columnDefinitionsServiceLevel from "@/jso/dn-serviceLevelColumns.json";
import { getLatestPublishedDate } from "@/js/dn-schedule-helper.js";
import { getSimData, SimState } from '@/js/dn-simulation.js';
import { getCurrentCCId, getLastAgentScheduleOptions, getLastScheduleOptions, getRequestFilterOptions, getScheduleColumnIds, getEmployeeDisplaySettings, getSimulationSettings, setScheduleColumnIds } from '@/js/dn-localStorage';
import { getScheduleRequest } from '@/model/dn-schedule-request.js';
import { ScheduleTasks } from '@/model/dn-task.js';
import { EventBus } from "@/js/dn-event-bus.js";

/** 
 * dateKeys already retrieved from database and up to date until given id:s
 * @type {{loaded:number[]; last?:{id:number;histId:number}}} 
 */
const _scheduleTaskInfo = { loaded: [] };

export const useScheduleStore = defineStore('schedule', {
  state: () => ({
    affintyIndicatorsMap: null,
    availabilityRequests: new AvailabilityRequests(),
    availabilities: shallowReactive(new Availabilities()),
    /**@type {import("@/model/dn-break-optimization-settings.js").BreakOptimizationSettings} */
    breakOptimizationSettings: null,
    customShifts: [],
    employeeContext: shallowReactive(new EmployeeContext()),
    employees: shallowReactive(new EmployeeList()),
    employeeDisplaySettings: {
      workTimeWarning: true,
      skillsWarning: true,
      shiftWarning: true,
      switchIdWarning: true,
      hasMobileApp: true,
      breakWarnings: true,
      multiEditSameValue: false
    },
    inspectorPanel: {
      timespan: 2.0,
      stQ: 0,
      runningStQ: 0,
      hideTimeSpan: false,
      serviceLevel: true,
    },
    loadCCData: false,
    pinnedRequestKey: 0,
    /** @type {import("@/model/dn-rotation").Rotations} */
    rotations: null,
    schedule: shallowReactive(new EmployeeScheduleList()),
    scheduleColumnDefinitions: getScheduleColumnDefinitions(),
    scheduleColumnDefinitionsDetailed: getScheduleColumnDefinitionsDetailed(),
    scheduleOptions: {
      /** @type {Date[]} */
      dateInterval: [],
      /** @type {Map<string, {dt:Date; emp:import("@/model/dn-employee.js").Employee}>} */
      selection: new Map(),     //key:getSelectionKey(dt,emp.empid) value:{emp:emp,dt:dt})
      currentSortMap: new Map(),//key:row index 0,1,2... value:emp
      /** @type {{st:number;fi:number;ttid:number}[][]} */
      copiedShifts: [],
      dragSelectionStart: {},   //{emp:emp,dt:dt}
      showServiceLevel: false,
      showDetailsInServiceLevel: false,
      showOccupancyChart: false,
      showAvgQueueChart: false,
      showWaitWithinChart: false,
      showWorkChart: false,
      showEmployeeOccupancy: false,
      showReplacementDialog: false,
      dispalySimulationSettings: false,
      showInactive: false,
      showDetailEdit: false,
      showRequestTile: false,
      selectedEmpId: 0,
      numberOfSimIterations: 1,
      autoSimulate: true,
      autoKeyFigures: false,
      numberOfDaysInSchedule: 7, //
      agentScheduleViewClock: true,
      agentScheduleDarkView: false,
      agentExpandedTeamView: false,
      agentAcceptedCookies: undefined,
      selectedDate: new Date(0),
      selectedDateInTeamSchedule: new Date(0),
      findReplacementSelected: false,
      scheduleFilter: { show: false, searchText: '', affinity: null, callGroups: [], taskTypes: [], tagIds: [], filterMsg: '', applyToChart: false,includeTerminated: false },
      chartDisplay: { erlangC: false, target: true, smooth: false, chartInspector: false, fixedY: false, fixedYvalue: 10, servicelevelPercentForWarning: 60, breaks: false,deviationTooFewBars:true,deviationTooManyBars:true },
      workChartHeight: 160,
      adherence: { showLog: false, showWorkWarnings: false, showBreakWarnings: false, showAdherenceWarnings: false, showInRotation: false },
      meetingSettings:{meetingLastTasktypeId:null,lastStartMinute:60*9,lastStartMinuteMax:60*16,lastDurationMinute:60,onlyOnShift:true,flexStart:false},
      notifications:{onScheduleChange:false},
      scheduleIndicators:{highlightBeforeSave:false,unsavedIndicators:false},
      saveButtonHovered:false,
    },
    scheduleRequestData: {
      /** @type {Map<number, import("@/model/dn-schedule-request.js").ScheduleRequest>} */
      byId: new Map(),
      /** @type {Map<string, import("@/model/dn-schedule-request.js").ScheduleRequest>} */
      bySelectionKey: new Map(),
      /** @type {import("@/model/dn-schedule-request.js").ScheduleRequest[]} */
      items: [],
      loaded: new Date(0),
      /** @type {number} */
      employeeId: null,
      /** @type {number} */
      ccId: null,
    },
    scheduleRequestSettings: {
      showSick: true,
      showVacation: true,
      showFree: true,
      showWork: true,
      showAvailability: false,
      showUnavailability: true,
      showPending: true,
      showFuture: false,
      showBasedOnSelection: false,
      showApproved: false,
      showRejected: false,
      showHistoric: false,
      tagId: 0
    },
    scheduleTasks: shallowReactive(new ScheduleTasks()),
    /** @type {number[]} */
    selectedScheduleColumnIds: [1, 2, 8, 9],
    serviceLevelColumnDefinitions: getServiceLevelColumnDefinitions(),
    /** @type {{ employeeOccupancyData:import("@/js/dn-simulation.js").EmployeeOccupancyData; occChartData:any; serviceLevelDisplayData:any;byAffinity:any }} */
    simData: null,
    simulationStatus: 0,
    simulationSettings: {
      displaySLSencitivity: 4,
      callsPercent: 0,
      handlingTimePercent: 0,
      handlingTimeVariationPercent: 0,
      percetageOfCallsInWaitChart: 80,
      empOccTarget: [35, 80],
    },
    /** @type {import("@/model/dn-optimizable-shift").ShiftBags} */
    shiftBags: null,
  }),
  getters: {
    getAffintyIndicatorsMap() {
      if (!this.affintyIndicatorsMap) {
        this.affintyIndicatorsMap = getAffintyIndicators(this.employees.list, 100);
      }
      return this.affintyIndicatorsMap;
    },
    getNumberOfDaysInSchedule() {
      if (this.scheduleOptions.dateInterval.length === 0) { return 0; }
      return getNumberOfDaysFromIntervalMinusOne(this.scheduleOptions.dateInterval);
    },
    scheduleNeedsToSave() {
      const currentUser = useDataStore().currentUser;
      if (currentUser === null || !currentUser.canEditScheduling)
        return false;
      return this.scheduleRequestData.items.some(x => x.isPending) ||
        (this.scheduleTasks.hasChanges && this.scheduleTasks.list.some(x => x.hasChanges && isAllowedToSave(x))) ||
        this.employees.hasChanges;
    }
  },
  actions: {
    clearScheduleFilter() {
      const scheduleFilter = this.scheduleOptions.scheduleFilter;
      scheduleFilter.affinity = null;
      scheduleFilter.applyToChart = false;
      scheduleFilter.show = true;
      scheduleFilter.searchText = '';
      scheduleFilter.callGroups = [];
      scheduleFilter.taskTypes = [];
      scheduleFilter.tagIds = [];
      //scheduleFilter.filterMsg = '';
    },
    clearScheduleTasks() {
      _scheduleTaskInfo.loaded = [];
      _scheduleTaskInfo.last = undefined;
      this.scheduleTasks.clear();
      this.scheduleOptions.selection = new Map();
      this.scheduleOptions.selectedEmpId = 0;
    },
    /**
     * @param {import("@/js/dn-simulation.js").OutputSpec} outputSpec
     */
    fetchSimData(outputSpec) {
      const dataStore = useDataStore();
      const forecastStore = useForecastStore();

      this.resetSimulationsStatus();

      const affinities = dataStore.affinities;
      const ccCallGroups = dataStore.callGroupsCurrentCC;
      const taskTypes = dataStore.taskTypes;
      /** @type {Set<number|null>} */
      const affinintyIdSet = new Set();
      for (const cg of ccCallGroups) {
        affinintyIdSet.add(cg.affinity);
      }

      const schedule = this.schedule.list;
      const byAffinity = [];
      for (const affinityId of affinintyIdSet) {
        const simState = new SimState(taskTypes, dataStore.dateNow, forecastStore.forecastAdjustmentmap,
          forecastStore.forecastOptions, forecastData.historicDataMap,forecastAiData.forecastDataMap, this.simulationSettings,
          specialDayData.specialDaysMap, ccCallGroups, dataStore.latestImportDate, dataStore.employeeCallcenters, dataStore.currentCC.id, affinityId);
        const simData = getSimData(
          outputSpec,
          simState,
          this.scheduleOptions,
          schedule,
          (p) => { this.simulationStatus = p; },
        );
        byAffinity.push({ affinityId: affinityId, simData });
      }


      /** @type {import("@/js/dn-simulation.js").ServicelevelDisplayData[]} */
      let serviceLevelDisplayData = undefined;
      if (outputSpec.sl) {
        if (byAffinity.length === 1) {
          serviceLevelDisplayData = byAffinity[0].simData.serviceLevelDisplayData;
        } else if (byAffinity.length > 1) {
          serviceLevelDisplayData = [];
          for (const r of byAffinity) {
            for (const sldd of r.simData.serviceLevelDisplayData) {
              if (sldd.acgrid === null) {
                const affinity = affinities.find(x => x.id === sldd.affinity)
                if (affinity) {
                  sldd.name = 'SL ' + affinity.name;
                }
              }
              serviceLevelDisplayData.push(sldd);
            }
          }
        }
      }

      /** @type {import("@/js/dn-simulation.js").EmployeeOccupancyData} */
      let employeeOccupancyData;
      if (outputSpec.employeeOccupancy) {
        if (byAffinity.length === 1) {
          employeeOccupancyData = byAffinity[0].simData.employeeOccupancyData;
        } else if (byAffinity.length > 1) {
          employeeOccupancyData = new Map();
          for (const r of byAffinity) {
            for (const [employeeId, occ] of r.simData.employeeOccupancyData) {
              let totOcc = employeeOccupancyData.get(employeeId);
              if (totOcc === undefined) {
                totOcc = new Array(occ.length).fill(0);
                employeeOccupancyData.set(employeeId, totOcc);
              }
              for (let i = 0; i < totOcc.length; i++) {
                totOcc[i] = totOcc[i] + occ[i];
              }
            }
          }
        }
      }

      /** @type {import("@/js/dn-simulation.js").ChartData[]} */
      let occChartData = undefined;
      if (outputSpec.occ) {
        if (byAffinity.length === 1) {
          occChartData = byAffinity[0].simData.occChartData;
        } else if (byAffinity.length > 1) {
          occChartData = [];
          for (const r of byAffinity) {
            for (const cd of r.simData.occChartData) {
              if (r.affinityId) {
                const affinity = affinities.find(x => x.id === r.affinityId)
                if (affinity) {
                  cd.label = 'Occ. ' + affinity.name;
                }
              }
              occChartData.push(cd)
            }
          }
        }
      }

      const simData = {
        employeeOccupancyData,
        occChartData,
        serviceLevelDisplayData,
        byAffinity
      };

      this.simData = simData;
    },
    async loadAvailabilities() {
      this.availabilities.load(useDataStore().currentCC.id);
    },
    /**
     * @param {number} ccId
     */
    async loadBreakOptimizationSettings(ccId) {
      this.breakOptimizationSettings = await getBreakOptimizationSettings(ccId);
    },
    async loadCurrentCCData() {
      const dataStore = useDataStore();
      if (!dataStore.employeeCallcenters.isLoaded) {
        dataStore.employeeCallcenters.load();
      }
      this.shiftBags = null;
      this.rotations = null;
      this.availabilities.clear();
      this.zeroSchedule();
      if (dataStore.currentCC) {
        const ccId = dataStore.currentCC.id;
        this.loadCCData = this.loadCCData || !dataStore.currentUser.isAgent;
        if (this.loadCCData) {
          this.availabilityRequests.init(ccId, false);
        } else {
          this.availabilityRequests.init(dataStore.currentUser.employee.id, true);
        }
        this.loadScheduleRequests();
        this.availabilities.load(ccId);
        if (!dataStore.currentUser.isAgent) {
          this.loadBreakOptimizationSettings(ccId);
          this.loadCustomShifts(ccId);
          this.loadShiftBags(ccId);
          this.loadRotations(ccId);
        }
        await this.loadEmployees(ccId);
      }
    },
    /**
     * @param {number} ccId
     */
    async loadEmployees(ccId) {
      await this.employees.load(ccId);
      this.clearScheduleTasks();
      this.affintyIndicatorsMap = null;
    },
    /**
     * @param {number} ccId
     */
    async loadRotations(ccId) {
      this.rotations = await getRotations(ccId);
    },
    /**
     * @param {number} ccId
     */
    async loadShiftBags(ccId) {
      this.shiftBags = await getShiftBags(ccId);
    },
    /**
     * @param {number} ccId
     */
    async loadCustomShifts(ccId) {
      this.customShifts = await getCustomShifts(ccId);
    },
    loadLocalAgentScheduleOptions() {
      const scheduleOptions = this.scheduleOptions;
      const localAgentScheduleOptions = getLastAgentScheduleOptions()

      if (localAgentScheduleOptions && useDataStore().currentUser.isAgent) {
        scheduleOptions.agentScheduleViewClock = localAgentScheduleOptions.agentScheduleViewClock
        scheduleOptions.agentExpandedTeamView = localAgentScheduleOptions.agentExpandedTeamView
        scheduleOptions.agentScheduleDarkView = localAgentScheduleOptions.agentScheduleDarkView
        scheduleOptions.agentAcceptedCookies=localAgentScheduleOptions.agentAcceptedCookies
      } else {
        scheduleOptions.agentScheduleViewClock = true
        scheduleOptions.agentExpandedTeamView=false
        scheduleOptions.agentScheduleDarkView = false
        scheduleOptions.agentAcceptedCookies=undefined
      }
    },
    loadLocalScheduleOptions() {
      let scheduleOptions = this.scheduleOptions;
      let inspector = this.inspectorPanel;
      let localScheduleOptions = getLastScheduleOptions();
      if (localScheduleOptions) {
        scheduleOptions.showServiceLevel = localScheduleOptions.showServiceLevel
        scheduleOptions.showOccupancyChart = localScheduleOptions.showOccupancyChart
        scheduleOptions.showWorkChart = localScheduleOptions.showWorkChart
        if (localScheduleOptions.workChartHeight > 0) { scheduleOptions.workChartHeight = localScheduleOptions.workChartHeight }
        scheduleOptions.showRequestTile = localScheduleOptions.showRequestTile
        scheduleOptions.showEmployeeOccupancy = localScheduleOptions.showEmployeeOccupancy
        if (!localScheduleOptions.numberOfSimIterations || localScheduleOptions.numberOfSimIterations == 0) {
          scheduleOptions.numberOfSimIterations = 1
        } else { scheduleOptions.numberOfSimIterations = localScheduleOptions.numberOfSimIterations }
        scheduleOptions.autoSimulate = localScheduleOptions.autoSimulate
        scheduleOptions.autoKeyFigures = localScheduleOptions.autoKeyFigures
        scheduleOptions.numberOfDaysInSchedule = localScheduleOptions.numberOfDaysInSchedule
        if (scheduleOptions.numberOfDaysInSchedule > 7) { scheduleOptions.numberOfDaysInSchedule = 7 }
        scheduleOptions.chartDisplay.erlangC = localScheduleOptions.chartDisplayErlangC
        scheduleOptions.chartDisplay.smooth = localScheduleOptions.chartDisplaySmooth
        scheduleOptions.chartDisplay.target = localScheduleOptions.chartDisplayTarget
        scheduleOptions.chartDisplay.chartInspector = localScheduleOptions.chartDisplaychartInspector
        scheduleOptions.chartDisplay.fixedY = localScheduleOptions.chartDisplayFixedY
        if (localScheduleOptions.chartDisplayFixedYvalue && localScheduleOptions.chartDisplayFixedYvalue > 0) scheduleOptions.chartDisplay.fixedYvalue = localScheduleOptions.chartDisplayFixedYvalue
        if (localScheduleOptions.chartDisplayServicelevelPercentForWarning && localScheduleOptions.chartDisplayServicelevelPercentForWarning > 0) scheduleOptions.chartDisplay.servicelevelPercentForWarning = localScheduleOptions.chartDisplayServicelevelPercentForWarning

      if(localScheduleOptions.chartDisplayBreaks!==null){scheduleOptions.chartDisplay.breaks=localScheduleOptions.chartDisplayBreaks}
      if(localScheduleOptions.chartDisplayDeviationTooFewBars!==null){ scheduleOptions.chartDisplay.deviationTooFewBars=localScheduleOptions.chartDisplayDeviationTooFewBars}
      if(localScheduleOptions.chartDisplayDeviationTooManyBars!==null){ scheduleOptions.chartDisplay.deviationTooManyBars=localScheduleOptions.chartDisplayDeviationTooManyBars}

        
        
        scheduleOptions.adherence.showLog = localScheduleOptions.showLog
        scheduleOptions.adherence.showWorkWarnings = localScheduleOptions.showWorkWarnings
        scheduleOptions.adherence.showBreakWarnings = localScheduleOptions.showBreakWarnings
        scheduleOptions.adherence.showAdherenceWarnings = localScheduleOptions.showAdherenceWarnings
        scheduleOptions.adherence.showInRotation = localScheduleOptions.showInRotation

        scheduleOptions.meetingSettings.meetingLastTasktypeId = localScheduleOptions.meetingLastTasktypeId      
       if(localScheduleOptions.meetingLastStartMinute){ scheduleOptions.meetingSettings.lastStartMinute = localScheduleOptions.meetingLastStartMinute}
       if(localScheduleOptions.meetingLastStartMinuteMax) {scheduleOptions.meetingSettings.lastStartMinuteMax = localScheduleOptions.meetingLastStartMinuteMax}
       if(localScheduleOptions.meetingLastOnlyOnShift){scheduleOptions.meetingSettings.onlyOnShift = localScheduleOptions.meetingLastOnlyOnShift}
       if(localScheduleOptions.meetingLastFlexStart){scheduleOptions.meetingSettings.flexStart = localScheduleOptions.meetingLastFlexStart}
       if(localScheduleOptions.meetingLastDurationMinute){scheduleOptions.meetingSettings.lastDurationMinute = localScheduleOptions.meetingLastDurationMinute}

       if(localScheduleOptions.notifyOnScheduleChange){ scheduleOptions.notifications.onScheduleChange = localScheduleOptions.notifyOnScheduleChange}

       if(localScheduleOptions.indicatorsHighlightBeforeSave){ scheduleOptions.scheduleIndicators.highlightBeforeSave = localScheduleOptions.indicatorsHighlightBeforeSave}
       if(localScheduleOptions.indicatorsUnsavedIndicators){ scheduleOptions.scheduleIndicators.unsavedIndicators = localScheduleOptions.indicatorsUnsavedIndicators}
       

        if (localScheduleOptions.inspectorPanelTimespan > 0) {
          inspector.timespan = localScheduleOptions.inspectorPanelTimespan
          inspector.hideTimeSpan = localScheduleOptions.inspectorHideTimeSpan
        
          inspector.serviceLevel = localScheduleOptions.inspectorServiceLevel
        }
      }
      this.loadLocalRequestOptions();
      this.loadScheduleColumnIds();
      this.loadEmployeeDisplaySettings();
    },
    loadLocalRequestOptions() {
      let scheduleRequestSettings = this.scheduleRequestSettings;
      let localRequestOptions = getRequestFilterOptions();
      if (localRequestOptions) {
        scheduleRequestSettings.showSick = localRequestOptions.showSick
        scheduleRequestSettings.showVacation = localRequestOptions.showVacation
        scheduleRequestSettings.showFree = localRequestOptions.showFree
        scheduleRequestSettings.showWork = localRequestOptions.showWork
        scheduleRequestSettings.showPending = localRequestOptions.showPending
        scheduleRequestSettings.showHistoric = localRequestOptions.showHistoric
        scheduleRequestSettings.showBasedOnSelection = localRequestOptions.showBasedOnSelection
        scheduleRequestSettings.showFuture = localRequestOptions.showFuture
        scheduleRequestSettings.showApproved = localRequestOptions.showApproved
        scheduleRequestSettings.showRejected = localRequestOptions.showRejected
        scheduleRequestSettings.tagId = localRequestOptions.tagId

      }
    },
    loadScheduleColumnIds() {
      const scheduleColumnIds = getScheduleColumnIds();
      if (scheduleColumnIds) {
        this.selectedScheduleColumnIds = scheduleColumnIds;
      }
    },
    loadEmployeeDisplaySettings() {
      const employeeDisplaySettings = getEmployeeDisplaySettings();
      if (employeeDisplaySettings) {
        this.employeeDisplaySettings = employeeDisplaySettings;
      }
    },
    async loadScheduleRequests(merge = false) {
      const currentUser = useDataStore().currentUser;
      if (currentUser === null) { return; }
      const currentData = this.scheduleRequestData;
      const scheduleRequestData = {
        byId: new Map(),
        bySelectionKey: new Map(),
        items: [],
        loaded: new Date(0),
        employeeId: null,
        ccId: null,
      };

      if (currentUser.isAgent) {
        if (!this.loadCCData)
          scheduleRequestData.employeeId = currentUser.employee.id;
        scheduleRequestData.ccId = currentUser.employee.ccid;
      } else {
        scheduleRequestData.ccId = getCurrentCCId();
      }

      if (scheduleRequestData.ccId) {
        const affectedDates = new Set();
        let items = await getScheduleRequest(scheduleRequestData.ccId, scheduleRequestData.employeeId);
        if (merge) {
          const toStore = [];
          for (const r of items) {
            const local = currentData.byId.get(r.id);
            if (local !== undefined) {
              if (local.needToBeRevertedBeforeUpdate(r)) {
                local.approveToggle(this.scheduleTasks.list, affectedDates);
              }
              local.update(r);
              toStore.push(local);
            } else {
              toStore.push(r);
            }
          }

          items = toStore;
        }

        scheduleRequestData.items = items;
        scheduleRequestData.byId = new Map(items.map(x => [x.id, x]));
        if (merge) {
          for (const r of currentData.byId.values()) {
            if (scheduleRequestData.byId.get(r.id) === undefined) {
              if (r.isPending && r.isApproved) {
                r.approveToggle(this.scheduleTasks.list, affectedDates);
              }
            }
          }
        }

        scheduleRequestData.bySelectionKey = calculateBySelectionKeyMap(scheduleRequestData.items);
        this.loadScheduleAfterScheduleRequest(affectedDates);
      }

      this.scheduleRequestData = scheduleRequestData;
      EventBus.emit("scheduleChange");
    },
    loadSchedule() {
      const dataStore = useDataStore();
      /** @type {Date[]} */
      const dateInterval = this.scheduleOptions.dateInterval;
      if (dateInterval.length === 0)
        return;

      const numberOfDays = getNumberOfDaysFromIntervalMinusOne(dateInterval)
      const stDate = this.scheduleOptions.dateInterval[0];
      const scheduleTasks = this.scheduleTasks.list;
      const employees = this.employees.list;
      const taskTypes = dataStore.taskTypes;
      this.schedule.load(numberOfDays, stDate, scheduleTasks, taskTypes, employees, this.scheduleOptions.showInactive);
      for (const employeeSchedule of this.schedule.list) {
        employeeSchedule.calculatePaidWork(numberOfDays, stDate);
      }
      EventBus.emit('fetchScheduleCompleted');
      EventBus.emit("scheduleChange");
    },
    /**
     * @param {Set<string>} affectedDates
     */
    loadScheduleAfterScheduleRequest(affectedDates) {
      if (affectedDates.size === 0) { return; }
      this.loadSchedule();
      EventBus.emit("schedulePerDtChange", Array.from(affectedDates));
      EventBus.emit("scheduleChangeUpdateTaskBars");
    },
    /**
     * @param {Date[]} timeInterval
     */
    async loadScheduleTasks(timeInterval) {
      const dataStore = useDataStore();
      const numberOfDays = 2 + getNumberOfDaysFromIntervalMinusOne(timeInterval);
      const scheduleTasksToCommit = this.scheduleTasks.list;
      const st = addDays(timeInterval[0], -1);
      const dateIntervals = getTaskDateIntervalsNotYetRetrieved(st, numberOfDays);
      if (dateIntervals.length > 0) {
        const idIsEmp = !this.loadCCData;
        const id = idIsEmp ? dataStore.currentUser.employee.id : dataStore.currentCC.id;
        /** @type {import("@/model/dn-task.js").Task[]} */
        let scheduleTasks;
        if (_scheduleTaskInfo.last) {
          scheduleTasks = await getScheduleTasks(dateIntervals, id, idIsEmp);
        } else {
          const tasksWithLast = await getScheduleTasksUpdated(dateIntervals, id, idIsEmp);
          scheduleTasks = tasksWithLast.tasks;
          _scheduleTaskInfo.last = tasksWithLast.last;
        }

        for (let i = 0; i < scheduleTasks.length; i++) {
          scheduleTasksToCommit.push(scheduleTasks[i])
        }

        this.scheduleTasks.load(scheduleTasksToCommit);
      }

      await this.availabilityRequests.load(timeInterval[0]);

      /**
       * @param {Date} st
       * @param {number} numberOfDays
       */
      function getTaskDateIntervalsNotYetRetrieved(st, numberOfDays) {
        const returnArray = []
        for (let d = 0; d < numberOfDays; d++) {
          const xSt = addDays(st, d); //eg:Wed Apr 01 2020 00:00:00 GMT+0200 (centraleuropeisk sommartid)
          let xNumberOfDays = 0
          let dtKey = xSt.getTime();
          if (!_scheduleTaskInfo.loaded.includes(dtKey)) {
            do {
              d++
              dtKey = addDays(st, d).getTime();
              xNumberOfDays++
            } while (!_scheduleTaskInfo.loaded.includes(dtKey) && d < numberOfDays)
            returnArray.push({ numberOfDays: xNumberOfDays, st: xSt })
          }
        }

        //update cach info
        for (let i = 0; i < returnArray.length; i++) {
          for (let d = 0; d < returnArray[i].numberOfDays; d++) {
            _scheduleTaskInfo.loaded.push(addDays(returnArray[i].st, d).getTime())
          }
        }

        return returnArray
      }
    },
    async refreshScheduleTasks() {
      const dataStore = useDataStore();

      _scheduleTaskInfo.loaded.sort();
      const dateIntervals = getLoadedDateIntervals();

      if (dateIntervals.length === 0) { return false; }
      const idIsEmp = !this.loadCCData;
      const id = idIsEmp ? dataStore.currentUser.employee.id : dataStore.currentCC.id;
      await this.availabilityRequests.refresh();
      const wasChanged = await updateScheduleTasks(dateIntervals, id, idIsEmp, _scheduleTaskInfo.last, this.scheduleTasks);
      this.scheduleTasks.setItemHasChanges();
      if (wasChanged) {
        this.loadSchedule();
      }
    },
    loadSimulationSettings() {
      const simulationSettings = getSimulationSettings();
      if (simulationSettings) {
        this.simulationSettings = simulationSettings;
      }
    },
    resetSimulationsStatus() {
      this.simulationStatus = 0;
    },
    saveScheduleColumnIds() {
      setScheduleColumnIds(this.selectedScheduleColumnIds);
    },
    

    async saveScheduleTasks() {
      await this.loadScheduleRequests(true);
      const scheduleTasksInStore = this.scheduleTasks.list;

      const scheduleRequests = this.scheduleRequestData.items;
      const changedRequests = scheduleRequests.filter(x => x.isPending);
      const hasChangedRequests = changedRequests.length > 0;
      console.log('saving scheduled tasks');

      /** @type {import("@/model/dn-task.js").Task[]} */
      const taskToCreate = [];
      /** @type {import("@/model/dn-task.js").Task[]} */
      const taskToUpdate = [];
      /** @type {import("@/model/dn-task.js").Task[]} */
      const taskToDelete = [];
      for (let i = 0; i < scheduleTasksInStore.length; i++) {
        if (isAllowedToSave(scheduleTasksInStore[i])) {
          if (!scheduleTasksInStore[i].isToDelete) {
            if (scheduleTasksInStore[i].hasChanges) {
              if (scheduleTasksInStore[i].id == -1) {
                taskToCreate.push(scheduleTasksInStore[i])
              }
              else {
                taskToUpdate.push(scheduleTasksInStore[i])
              }
            }
          } else {
            taskToDelete.push(scheduleTasksInStore[i])
          }
        }
      }
      if (taskToCreate.length > 0 || taskToDelete.length > 0 || taskToUpdate.length > 0 || changedRequests.length > 0) {
        try {
          do {
            const createWeight = 6;
            const updateWeight = 7;
            const requestWeight = 50;
            let left = 50000;

            const deleteItems = taskToDelete.splice(0, Math.min(taskToDelete.length, left));
            const deleteBatch = deleteItems.map(x => x.id);
            left -= deleteBatch.length;

            const updateItems = taskToUpdate.splice(0, Math.min(taskToUpdate.length, Math.floor(left / updateWeight)));
            const updateBatch = updateItems.map(t => t.toDto());
            left -= updateBatch.length * updateWeight;

            const createItems = taskToCreate.splice(0, Math.min(taskToCreate.length, Math.floor(left / createWeight)));
            const createBatch = createItems.map(t => t.toDto());
            left -= createBatch.length * createWeight;

            const requestItems = changedRequests.splice(0, Math.min(changedRequests.length, Math.floor(left / requestWeight)));
            const requestBatch = requestItems.map(x => x.getApiReply());
            left -= requestBatch.length * requestWeight;

            const values = await fetchAndCheckJson(`scheduletasks`, 'POST',
              { create: createBatch, delete: deleteBatch, update: updateBatch, requestReplies: requestBatch });

            for (let i = 0; i < deleteItems.length; i++) {
              var index = scheduleTasksInStore.indexOf(deleteItems[i]);
              scheduleTasksInStore.splice(index, 1);
            }

            for (let i = 0; i < createItems.length; i++) {
              if (values[i].id === null) {
                createItems[i].toDelete();
              } else {
                createItems[i].id = values[i].id;
                createItems[i].confirmChanges();
              }
            }

            for (const t of updateItems) {
              t.confirmChanges();
            }

            for (const sr of requestItems) {
              sr.setAsNonPending();
            }

          } while (taskToCreate.length > 0 || taskToDelete.length > 0 || taskToUpdate.length > 0 || changedRequests.length > 0);

          if (hasChangedRequests) {
            await this.loadScheduleRequests(true);
          }
          await this.refreshScheduleTasks();
        } catch (error) {
          console.error(error.message)
        }
      }
    },
    setLoadCCData() {
      if (!this.loadCCData) {
        this.loadCCData = true;
        this.clearScheduleTasks();
        const cc = useDataStore().currentCC;
        if (cc) {
          this.availabilityRequests.init(cc.id, false);
        }
      }
    },
    /**
     * @param {number} id
     */
    toggleScheduleColumn(id) {
      const tmp = this.selectedScheduleColumnIds;
      const selectedColumns = [];
      let found = false;
      tmp.forEach(item => {
        if (item === id) {
          found = true;
        } else {
          selectedColumns.push(item);
        }
      });
      if (!found) {
        const column = this.scheduleColumnDefinitions.find((item) => item.id === id);
        selectedColumns.push(column.id);
      }
      selectedColumns.sort((a, b) => a - b);
      this.selectedScheduleColumnIds = selectedColumns;
    },
    zeroSchedule() {
      this.schedule.clear(); //force tableSchedule to reloag when filtering, needed for scheduleOptions.currentSortMap
    }
  }
});

/** @returns {{st:Date; numberOfDays:number}[]} */
function getLoadedDateIntervals() {
  const dateIntervals = [];

  const msPerDay = 1000 * 60 * 60 * 24;
  /** @type {Date|null} */
  let st = null;
  let numberOfDays = 0;
  let last = 0;
  for (const msSinceEpoch of _scheduleTaskInfo.loaded) {
    if (st === null) {
      st = new Date(msSinceEpoch);
      numberOfDays = 1;
    } else if (Math.round((msSinceEpoch - last) / msPerDay) > 1) {
      dateIntervals.push({ st, numberOfDays });
      st = new Date(msSinceEpoch);
      numberOfDays = 1;
    } else {
      numberOfDays += 1;
    }
    last = msSinceEpoch;
  }
  if (st !== null) {
    dateIntervals.push({ st, numberOfDays });
  }
  return dateIntervals;
}

function getScheduleColumnDefinitions() {
  return columnDefinitions;
}

function getScheduleColumnDefinitionsDetailed() {
  return columnDefinitionsDetailed;
}

function getServiceLevelColumnDefinitions() {
  return columnDefinitionsServiceLevel;
}

/**
 * @param {import("@/model/dn-schedule-request.js").ScheduleRequest[]} requests
 */
function calculateBySelectionKeyMap(requests) {
  //set a request map for easy access during schedule rendering

  /** @type {Map<string, import("@/model/dn-schedule-request.js").ScheduleRequest>} */
  let requestMap = new Map()
  for (let i = 0; i < requests.length; i++) {
    let keys = requests[i].selectionKeys

    for (let k = 0; k < keys.length; k++) {
      if (!requestMap.has(keys[k])) { requestMap.set(keys[k], requests[i]) }
      //one day may include several requests but the map only includes one item per key.
      //therefor the Map is giving prio to not handled requests
      else if (!requests[i].isApproved || !requests[i].isRejected) {
        requestMap.delete(keys[k])
        requestMap.set(keys[k], requests[i])
      }
    }
  }
  return requestMap;
}

/**
 * @param {{st:Date}} t
 */
function isAllowedToSave(t) {
  const currentUser = useDataStore().currentUser;
  return !currentUser.isSupervisor || (currentUser.isSupervisor) && t.st <= addDays(getLatestPublishedDate(), 1)
}