import { fetchAndCheckJson } from '@/js/dn-fetch.js';

/**
 * @param {import("@/model/dn-tasktype.js").TaskType} taskType
 */
export async function deleteTaskType(taskType) {
  return await fetchAndCheckJson(`tasktypes/${taskType.id}`, 'DELETE');
}

export async function getTaskTypes() {
  /** @type {import("@/model/dn-tasktype.js").TaskTypeDto[]} */
  const dtoList = await fetchAndCheckJson(`tasktypes`, 'GET');
  return dtoList;
}

/**
 * @param {import("@/model/dn-tasktype.js").TaskType} taskType
 */
export async function patchTaskType(taskType) {
  return await fetchAndCheckJson(`tasktypes/${taskType.id}`, 'PATCH', taskType.toDto());
}

/**
 * @param {import("@/model/dn-tasktype.js").TaskType} taskType
 */
export async function postTaskType(taskType) {
  return await fetchAndCheckJson(`tasktypes`, 'POST', taskType.toDto());
}
