import { addDays, getStartOfWeek, getShortDate } from '@/js/dn-helper.js';
import { getCallStatistics } from '@/js/dn-call-statistics.js';
import { getDaytype } from '@/js/dn-forecast.js';

class ForecastData {
  constructor() {
    /** @private @type {Map<string, { dt: Date; acgr: number; ahtData: number[]; nocData: number[]; dayType:number}>} */
    this._historicDataMap = new Map();
  }

  get historicDataMap() {
    return this._historicDataMap;
  }

  /**
   * @param {Date} focusDt
   * @param {{ historicWeeks: number; historicFocusDate: Date; percentOfAbandonsToInclude: number; }} forecastOptions
   * @param {{id:number}[]} dayTypes
   * @param {Map<string, number>} specialDaysMap
   */
  async loadHistoricData(focusDt, forecastOptions, dayTypes, specialDaysMap) {
    if (forecastOptions.historicFocusDate !== null) {
      focusDt = forecastOptions.historicFocusDate;
    }
    /** @type {Map<string, { dt: Date; acgr: number; ahtData: number[]; nocData: number[]; dayType:number}>} */
    const historicDataMap = new Map();
    const startDate = getStartOfWeek(addDays(focusDt, -forecastOptions.historicWeeks * 7)); /* mon or sun of the youngset week */
    const ipoa = forecastOptions.percentOfAbandonsToInclude;
    const result = await getCallStatistics({startTimeUTC:startDate, quarters: (forecastOptions.historicWeeks + 1) * 7 * 96, ipoa});
    if (result.isCalculating)
      return false;

    const msPerQuarterHour = 1000 * 60 * 15;
    for (const histData of result.data) {
      for (let d = 0; d < (forecastOptions.historicWeeks + 1) * 7; d++) {
        const dt = addDays(startDate, d)
        //supporting daylight saving transition
        const nextDt = addDays(startDate, d + 1);
        const quarterAtDayEnd = Math.round((nextDt.getTime() - startDate.getTime()) / msPerQuarterHour);
        const quarterAtDaystart = Math.round((dt.getTime() - startDate.getTime()) / msPerQuarterHour);
        const key = histData.cgId + '_' + getShortDate(dt);
        const noc = histData.noc.slice(quarterAtDaystart, quarterAtDayEnd)
        const aht = histData.ht.slice(quarterAtDaystart, quarterAtDayEnd)

        let xVal = {
          dt: dt,
          acgr: histData.cgId,
          dayType: getDaytype(dt, specialDaysMap),
          nocData: noc,
          ahtData: aht
        };

        historicDataMap.set(key, daysavingTransitionCorrection(xVal))
      }
    }

    const ok = await this.loadHistoricDataSpecialDays(dayTypes, startDate, forecastOptions, historicDataMap, specialDaysMap);
    if (!ok) {
      return false;
    }

    this._historicDataMap = historicDataMap;
    return true;
  }

  /**
   * @private
   * @param {{id:number}[]} dayTypes
   * @param {Date} startDate
   * @param {{ historicWeeks: number; historicFocusDate: Date; percentOfAbandonsToInclude: number; }} forecastOptions
   * @param {Map<string, { dt: Date; acgr: number; ahtData: number[]; nocData: number[]; dayType:number}>} historicDataMap
   * @param {Map<string, number>} specialDaysMap
   */
  async loadHistoricDataSpecialDays(dayTypes, startDate, forecastOptions, historicDataMap, specialDaysMap) {
    const ipoa = forecastOptions.percentOfAbandonsToInclude;
    let counterMap = new Map();
    for (let i = 0; i < dayTypes.length; i++) { counterMap.set(dayTypes[i].id, 0) }

    /** @type {{st:Date;quarters:number}[]} */
    const intervals = [];
    for (let d2 = 0; d2 < 365 * 6; d2++) {    //earlier then the normal x weeks of data
      let dt2 = addDays(startDate, -d2)
      let dayTypeId = getDaytype(dt2, specialDaysMap)
      if (dayTypeId > 0 && counterMap.get(dayTypeId) < 10) {  //dates with specialDay and only the 10 latest
        intervals.push({st:dt2, quarters:96});
        let count = counterMap.get(dayTypeId)
        counterMap.set(dayTypeId, count + 1)
      }
    }

    const csResult = await getCallStatistics({intervals, ipoa});
    if (csResult.isCalculating)
      return false;
    for (const dr of csResult.list) {
      const dt2 = new Date(dr.st);
      const dayTypeId = getDaytype(dt2, specialDaysMap)
      const dt = getShortDate(dt2);
      for (const histData of dr.data) {
        let key2 = histData.cgId + '_' + dt;
        let xVal = {
          dt: dt2,
          acgr: histData.cgId,
          dayType: dayTypeId,
          nocData: histData.noc,
          ahtData: histData.ht
        };

        historicDataMap.set(key2, daysavingTransitionCorrection(xVal))
      }
    }

    return true;
  }

  reset() {
    this._historicDataMap = new Map();
  }
}

export const forecastData = new ForecastData();

/**
 * @param {{ dt: Date; acgr: number; dayType: number; nocData: number[]; ahtData: number[]; }} xVal
 */
function daysavingTransitionCorrection(xVal) {
  if (xVal.nocData.length > 96) {
    xVal.nocData = xVal.nocData.slice(0, 96)
    xVal.ahtData = xVal.ahtData.slice(0, 96)
  }
  if (xVal.nocData.length == 92) {
    for (let i = 0; i < 4; i++) {
      xVal.nocData.push(0)
      xVal.ahtData.push(0)
    }
  }
  return xVal
}