import { fetchAndCheckJson } from '@/js/dn-fetch.js';
import { getShortDate } from '@/js/dn-helper.js';

class SpecialDayData {
  constructor() {
    /** @private @type {Map<string, number>} */
    this._specialDaysMap = new Map();
  }

  get specialDaysMap() {
    return this._specialDaysMap;
  }

  async loadSpecialDays() {
    /** @type {{dt:string;dtid:number}[]} */
    const rows = await fetchAndCheckJson(`specialday`, 'GET');
    /** @type {Map<string, number>} */
    const daytypeMap = new Map();
    for (const r of rows) {
      daytypeMap.set(r.dt, r.dtid);
    }
    this._specialDaysMap = daytypeMap;
  }

  /**
   * @param {Date} dt
   * @param {number} dtid
   * @param {boolean} system
   */
  async saveSpecialDay(dt, dtid, system) {
    const dtString = getShortDate(dt);
    if (system && this._specialDaysMap.has(dtString)) {
      this._specialDaysMap.delete(dtString);
    } else {
      this._specialDaysMap.set(dtString, dtid);
    }
    const id = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
    if (!system) {
      await fetchAndCheckJson(`specialday/${id}`, 'PUT', { dtid });
    }
    else {
      await fetchAndCheckJson(`specialday/${id}`, 'DELETE');
    }
  }

  reset() {
    this._specialDaysMap = new Map();
  }
}

export const specialDayData = new SpecialDayData();
