import { fetchAndCheckJson } from '@/js/dn-fetch.js';
import { getUniqueNegativeId,timeRangeInformation } from '@/js/dn-helper.js';

/**
* @typedef {{id: number,daysOfWeek: number[], blackHours:[number,number],grade:number,taskTypeId:number}} BreakBlackoutDto
*/

/**
* @typedef {{
* callcenterId: number;
* spreadFactor: number;
* avoidSimultanousBreaksFactor: number;
* numberOfIterations: number;
* breakStartQuarterHours:boolean;
* blackouts: BreakBlackoutDto[]
* }} BreakOptimizationSettingsDto
*/

export class BreakOptimizationSettings {
  /**
   * @param {BreakOptimizationSettingsDto} dto
   */
  constructor(dto) {
    /** @private @type {number} */
    this.callcenterId = dto.callcenterId;
    /** @private @type {number} */
    this._spreadFactor = dto.spreadFactor;
    /** @private @type {number} */
    this._avoidSimultanousBreaksFactor = dto.avoidSimultanousBreaksFactor;
    /** @private @type {number} */
    this._numberOfIterations = dto.numberOfIterations;
    /** @private @type {boolean} */
    this._breakStartQuarterHours = dto.breakStartQuarterHours;
    /** @private @type {BreakOptimizationSettingDetail[]} */
    this._blackouts = []
    for (const blackout of dto.blackouts) {
      this._blackouts.push(new BreakOptimizationSettingDetail(this, blackout));
    }
  }

  get spreadFactor() {
    return this._spreadFactor;
  }
  set spreadFactor(newValue) {
    this.markAsHasChanges();
    this._spreadFactor = newValue;
  }

  get avoidSimultanousBreaksFactor() {
    return this._avoidSimultanousBreaksFactor;
  }
  set avoidSimultanousBreaksFactor(newValue) {
    this.markAsHasChanges();
    this._avoidSimultanousBreaksFactor = newValue;
  }

  get numberOfIterations() {
    return this._numberOfIterations;
  }
  set numberOfIterations(newValue) {
    this.markAsHasChanges();
    this._numberOfIterations = newValue
  }

  get breakStartQuarterHours() {
    return this._breakStartQuarterHours;
  }
  set breakStartQuarterHours(newValue) {
    this.markAsHasChanges();
    this._breakStartQuarterHours = newValue
  }

  get blackouts() {
    return this._blackouts;
  }

  get hasChanges() {
    return this._hasChanges;
  }

  addBlackout() {
    const id = getUniqueNegativeId(this.blackouts);
    this.blackouts.push(new BreakOptimizationSettingDetail(this, { id, daysOfWeek: [0, 1, 2, 3, 4, 5, 6], blackHours: [17*12, 18*12], grade: 30, taskTypeId: null }));
  }

  /**
   * @param {{ id: number; }} row
   */
  deleteBlackout(row) {
    const index = this.blackouts.findIndex(x => x.id === row.id);
    this.blackouts.splice(index, 1);
    this.markAsHasChanges();
  }

  markAsHasChanges() {
    this._hasChanges = true;
  }

  confirmChanges() {
    this._hasChanges = false;
  }

  async save() {
    await fetchAndCheckJson(`break-optimization-settings`, 'POST', this.toDto());
    this.confirmChanges();
  }

  /** @returns {BreakOptimizationSettingsDto} */
  toDto() {
    return {
      callcenterId: this.callcenterId, spreadFactor: this._spreadFactor,
      avoidSimultanousBreaksFactor: this._avoidSimultanousBreaksFactor, numberOfIterations: this._numberOfIterations,
      breakStartQuarterHours: this._breakStartQuarterHours, blackouts: this._blackouts.map(x => x.toDto())
    };
  }
}

export class BreakOptimizationSettingDetail {
  /**
   * @param {BreakOptimizationSettings} parent
   * @param {BreakBlackoutDto} dto
   */
  constructor(parent, dto) {
    /** @private @type {number} */
    this._id = dto.id;
    /** @private @type {number[]} */
    this._daysOfWeek = dto.daysOfWeek;
    /** @private @type {[number,number]} */
    this._blackHours = dto.blackHours;
    /** @private @type {number} */
    this._grade = dto.grade;
    /** @private @type {number} */
    this._taskTypeId = dto.taskTypeId;
    /** @private @type {BreakOptimizationSettings} */
    this.parent = parent;
  }

  get id() {
    return this._id;
  }

  get daysOfWeek() {
    return this._daysOfWeek;
  }
  set daysOfWeek(newValue) {
    this.parent.markAsHasChanges();
    this._daysOfWeek = newValue
  }

  get blackHours() {
    return this._blackHours;
  }
  set blackHours(newValue) {
    this.parent.markAsHasChanges();
    this._blackHours = newValue
  }

  get blackGrade() {
    return this._grade;
  }
  set blackGrade(newValue) {
    this.parent.markAsHasChanges();
    this._grade = newValue;
  }

  get taskTypeId() {
    return this._taskTypeId;
  }
  set taskTypeId(newValue) {
    this.parent.markAsHasChanges();
    this._taskTypeId = newValue;
  }

  /**
 * @param {Intl.LocalesArgument} lang
 */
  blackHoursInfo(lang) {
    return timeRangeInformation(lang,this.blackHours,3);
  }

  /** @returns {BreakBlackoutDto} */
  toDto() {
    /** @type {[number, number]} */
    const blackHours = [this.blackHours[0],this.blackHours[1]];
    return { id: this.id, daysOfWeek: this.daysOfWeek.slice(), blackHours, grade: this._grade, taskTypeId: this.taskTypeId };
  }
}

/**
 * @param {number} callcenterId
 */
export async function getBreakOptimizationSettings(callcenterId) {
  /** @type {BreakOptimizationSettingsDto} */
  let dto = await fetchAndCheckJson(`break-optimization-settings?ccId=${callcenterId}`, 'GET');
  if (!dto) {
    dto = {
      callcenterId, breakStartQuarterHours: true,
      spreadFactor: 50, avoidSimultanousBreaksFactor: 50, numberOfIterations: 1, blackouts: []
    };
  }
  return new BreakOptimizationSettings(dto);
}