import { fetchAndCheckJson } from '@/js/dn-fetch.js';
import { addDays } from '@/js/dn-helper';
import { Task } from './dn-task';

/**
* @typedef {{id:number; ts:string; scheduleTaskId:number; employeeId:number; st:string; fi:string|null; taskTypeId:number;
*  comment:string|null; reasonCode:string|null; corrSt:number|null; corrFi:number|null;userId:number|null; deleted:boolean|null, updated:string|null}} ScheduleTaskHistoryDto
*/

/**
 * @param {number} employeeId
 * @param {Date} st
 * @return {Promise<ScheduleTaskHistory[]>}
 */
export async function getScheduleTaskHistory(employeeId, st) {
  const fi = addDays(st, 1);
  /** @type {ScheduleTaskHistoryDto[]} */
  const result = await fetchAndCheckJson(`schedule-task-history?employeeId=${employeeId}&st=${st.toISOString()}&fi=${fi.toISOString()}`, 'GET');
  return result.map(x => new ScheduleTaskHistory(x));
}

export class ScheduleTaskHistory extends Task {
  /**
   * @param {ScheduleTaskHistoryDto} dto
   */
  constructor(dto) {
    super(convertToTaskDto(dto))
    /** @readonly @type {number} */
    this.scheduleTaskHistoryId = dto.id;
    /** @readonly @type {boolean} */
    this.deleted = dto.deleted;
    /** @readonly @type {Date} */
    this.ts = new Date(dto.ts);
  }
}

/**
 * @param {ScheduleTaskHistoryDto} dto
 * @returns {import('@/model/dn-task').TaskDto}
 */
function convertToTaskDto(dto) {
  return {
    id: dto.scheduleTaskId,
    empid: dto.employeeId,
    st: dto.st,
    fi: dto.fi,
    comment: dto.comment,
    tasktypeId: dto.taskTypeId,
    corrSt: dto.corrSt,
    corrFi: dto.corrFi,
    reasonCode: dto.reasonCode,
    updated: dto.updated,
    userId: dto.userId,
  };
}