import { defineStore } from 'pinia';
import { importAgentEventsCsv } from '@/js/dn-agent-event.js';
import { importCallsFile } from "@/js/dn-import-calls.js";

export const useFileImportStore = defineStore('fileImport', {
  state: () => ({
    callImportFileType: 0,
    dropFiles: [],
    fileImportInfo: {
      context: [],
      nofImported: 0,
      error: false,
      fileName: ""
    },
    importCallsLoadingStatus: 0,
    lastImportFileSetting:{
      lastCallImportFile:0,
      lastAgentImportFile:0 //not in use
    },
  }),
  getters: {
  },
  actions: {
    /**
     * Import calls or agent events csv files.
     * @param {{ blob:Blob; fileCount:number; agentEvents:boolean; callImportFileType:number; switchId:number}} fileInfo
     */
    async executeCsvImport(fileInfo) {
      const blob = fileInfo.blob;
      const dropFileLengh = fileInfo.fileCount;
      const startProgress = this.importCallsLoadingStatus;
      try {
        const updateProgress = progress => {
          if (startProgress + 100 * progress / dropFileLengh < 100) {
            this.importCallsLoadingStatus = startProgress + 100 * progress / dropFileLengh;
          } else { this.importCallsLoadingStatus = 100 }
        };

        let content = await blob.text();
        content = content.replace(/(\r)/gm, '')
        if (fileInfo.agentEvents ||  fileInfo.callImportFileType !== 3) {
          content = content.replace(/,/g, ';').replace(/"/g, '');
        }
        
        const lines = content.split('\n');
        if (fileInfo.agentEvents) {
          await importAgentEventsCsv(lines, fileInfo.switchId, updateProgress);
        } else {
          await importCallsFile(lines, fileInfo.callImportFileType, fileInfo.switchId, updateProgress);
        }

        const fileImportInfo = this.fileImportInfo;
        fileImportInfo.nofImported += 1;
        fileImportInfo.fileName = blob.name
        fileImportInfo.context.push({msg:'File' + blob.name + ': ' + (lines.length - 1) + ' rows',severity:"success"});
      } catch (error) {
        const fileImportInfo = this.fileImportInfo;
        fileImportInfo.error = true;
        fileImportInfo.fileName = blob.name
        fileImportInfo.context.push({msg:'File' + blob.name + ':' + error,severity:"error"});
      }
    },
  }
}
);
