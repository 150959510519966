import { defineStore } from 'pinia';
import { fetchAndCheckJson } from '@/js/dn-fetch.js';
import { forecastData } from '@/stores/dn-forecast-data.js';
import { forecastAiData } from '@/stores/dn-forecast-ai-data.js';
import { specialDayData } from '@/stores/dn-special-day-data.js';
import { useDataStore } from './dataStore';
import { getAllWeekData, getCallStatsWeek, getChartData, updateForecastInAllWeekData } from '@/js/dn-forecast.js';
import { getLastForcastOptions,setLastForcastOptions } from '@/js/dn-localStorage';

let _isLoadingHistoricData = false;
let _isLoadingAiForecast = false;

export const useForecastStore = defineStore('forecast', {
  state: () => ({
    callStatsWeek: [],
    callStatsWeekMap: new Map(),
    chartData: [],
    /** @type {Map<string, Map<number, any>>} */
    forecastAdjustmentmap: new Map(),
    forecastOptions: {
      selectedParameters: ['noc'],
      compareWithForecast: true,
      /**@type {{id: number; system: boolean; }}; */
      focusedDaytyp: { id: undefined, system: undefined },
      /** @type {{id:number;forecastMethod:number}[]} */
      selectedCallGroups: [],
      selectedCallCenters: [],
      selectedDates: [],
      adjustmentDate: null,
      adjustmentDialogShow:false,
      historicWeeks: 6,
      forecastWeeks: 3,
      numberOfHistoricSpecialDays: 10,
      occupacyFte: 90,
      adherancePercent: 89,
      forecastIsLoaded: false,
      /** @type {Date} */
      historicFocusDate: null,
      compareSencitivity: 0,
      percentOfAbandonsToInclude: 50,
      useAiForecast:true,
      aiForecastIsLoaded: false,
      copiedData:null,
    },
  }),
  actions: {
    /* initial fetch of callstatsWeek */
    fetchForecast() {
      const dataStore = useDataStore();
      const dayTypes = dataStore.dayTypes;
      this.callStatsWeekMap = getAllWeekData(
        dataStore.dateNow, this.forecastOptions, specialDayData.specialDaysMap, forecastData.historicDataMap, dataStore.callGroupsAllowed, dayTypes);
    },
    async initForecast() {
      const dataStore = useDataStore();
      const forecastStore = useForecastStore();
      const promises = []
      promises.push(dataStore.loadDayTypes())
      promises.push(dataStore.loadCallGroups())
      promises.push(specialDayData.loadSpecialDays());
      promises.push(dataStore.loadSwitches());
      promises.push(dataStore.backOfficeSkills.load());
      await Promise.all(promises)
      forecastStore.loadLocalForcastOptions(); //dependent on callGroups
      await forecastStore.loadForecastAdjustment();
      await forecastStore.reloadForecastAndHistoricData();
    },

    async reloadAiForecast() {
      if (_isLoadingAiForecast)
        return;
      const dataStore = useDataStore();
      if (dataStore.dateNow === null) { return; }
      _isLoadingAiForecast = true;
      try {
        
        this.forecastOptions.aiForecastIsLoaded = false;
        let ok = false;
        do {
          ok = await forecastAiData.loadAiForecastData(this.forecastOptions);
          if (ok) {
            this.forecastOptions.aiForecastIsLoaded = true;
            this.fetchForecast();
            this.updateForecast();
          } else {
            await new Promise((resolve) => { setTimeout(() => resolve(null), 30000); });
          }
        } while (!ok);
      } catch (error) {
        console.log(error);
      } finally {
        _isLoadingAiForecast = false;
      }
    },

    async reloadForecastAndHistoricData() {
      if (_isLoadingHistoricData)
        return;
      const dataStore = useDataStore();
      if (dataStore.dateNow === null) { return; }
      _isLoadingHistoricData = true;
      try {
        const dayTypes = dataStore.dayTypes;
        this.forecastOptions.forecastIsLoaded = false;
        let ok1 = false;
        let ok2= false;
        do {
          ok1 = await forecastData.loadHistoricData(dataStore.dateNow, this.forecastOptions, dayTypes, specialDayData.specialDaysMap);
          if(this.forecastOptions.useAiForecast){
            ok2 = await forecastAiData.loadAiForecastData(this.forecastOptions);
          }else{ok2=true}
          if (ok1&&ok2) {
            this.forecastOptions.forecastIsLoaded = true;
            this.fetchForecast();
            this.updateForecast();
          } else {
            await new Promise((resolve) => { setTimeout(() => resolve(null), 30000); });
          }
        } while (!(ok1&&ok2));
      } catch (error) {
        console.log(error);
      } finally {
        _isLoadingHistoricData = false;
      }
    },
    /* recalculate callstatsWeek with updated forecast */
    updateForecast() {  
      const dataStore = useDataStore();
      this.callStatsWeekMap = updateForecastInAllWeekData(this.callStatsWeekMap, dataStore.dateNow, this.forecastOptions, specialDayData.specialDaysMap,
        this.forecastAdjustmentmap, forecastData.historicDataMap,forecastAiData.forecastDataMap, dataStore.callGroupsAllowed, dataStore.latestImportDate);
      this.callStatsWeek = getCallStatsWeek(this.callStatsWeekMap, this.forecastOptions, specialDayData.specialDaysMap)
    },
    /* Recalcualte CallStatweek with new selection of callgroups and focused daytype */
    updateCallStatsWeek() {
      this.callStatsWeek = getCallStatsWeek(this.callStatsWeekMap, this.forecastOptions, specialDayData.specialDaysMap)
    },
    async loadForecastAdjustment() {
      const dataStore = useDataStore();
      /** @type {{dt:string; adj:number;cgid:number;quarterRange:null|[number,number];static_noc:null|number[];static_ht:null|number[]}[]} */
      const rows = await fetchAndCheckJson(`forecastadjustment`, 'GET');
      const callGroups = dataStore.callGroupsAllowed;
      /** @type {Map<string, Map<number, any>>} */
      const adjustmentMap = new Map();
      for (let i = 0; i < rows.length; i++) {
        const key = rows[i].dt.substring(0, 10);
        /** @type {Map<number, any>} */
        let dayMap;
        if (adjustmentMap.has(key)) {
          dayMap = adjustmentMap.get(key)
        } else {
          dayMap = new Map()
          adjustmentMap.set(key, dayMap);
        }
        const rec = rows[i]
        for (let a = 0; a < callGroups.length; a++) {
          if (callGroups[a].id == rec.cgid) {
            let staticData = null
            const qr = rec.quarterRange ? rec.quarterRange : [0,96];
            if (rec.static_noc&&rec.static_ht){staticData={noc:rec.static_noc,ht:rec.static_ht}}
            dayMap.set(rec.cgid, {nocAdj:rec.adj,quarterRange:qr,copiedNocAht:staticData});
          }
        }
      }
      this.forecastAdjustmentmap = adjustmentMap;
    },
    loadLocalForcastOptions() {
      const dataStore = useDataStore();
      const forecastOptions = this.forecastOptions
      let localForecastOptions = getLastForcastOptions()
      if (localForecastOptions && localForecastOptions.selectedAcGrIds) {
        let selectedCallGroups = []
        const callGroups = dataStore.callGroupsAllowed;
        for (let i = 0; i < localForecastOptions.selectedAcGrIds.length; i++) {
          for (let a = 0; a < callGroups.length; a++) {
            if (localForecastOptions.selectedAcGrIds[i] == callGroups[a].id) {
              selectedCallGroups.push(callGroups[a])
            }
          }
        }

        forecastOptions.selectedParameters = localForecastOptions.selectedParameters
        forecastOptions.compareWithForecast = localForecastOptions.compareWithForecast
        forecastOptions.selectedCallGroups = selectedCallGroups
        if(localForecastOptions.selectedCallCenters){forecastOptions.selectedCallCenters=localForecastOptions.selectedCallCenters}else{forecastOptions.selectedCallCenters=[]}
      }

      const dayTypes = dataStore.dayTypes;
      dayTypes.forEach((dt) => {
        if (dt.system) { forecastOptions.focusedDaytyp = dt }
      })
    },
    saveLocalForecastOptions(){
      let localForecastOptions = {
        selectedParameters: this.forecastOptions.selectedParameters,
        compareWithForecast: this.forecastOptions.compareWithForecast,
        //selectedCallGroups:this.forecastOptions.selectedCallGroups,
        selectedAcGrIds: this.forecastOptions.selectedCallGroups.map(function (x) { return x.id }),
        selectedDates: this.forecastOptions.selectedDates,
        selectedCallCenters:this.forecastOptions.selectedCallCenters
        
      }
      setLastForcastOptions(localForecastOptions)
    },
    setChartData() {
      const dataStore = useDataStore();
      this.chartData = getChartData(this.forecastOptions, specialDayData.specialDaysMap, dataStore.dateNow, this.forecastAdjustmentmap, forecastData.historicDataMap,forecastAiData.forecastDataMap, dataStore.latestImportDate);
    },
  }
});
