import { fetchAndCheckJson } from '@/js/dn-fetch.js';
import { addDays } from '@/js/dn-helper.js';
import { createSchedule } from "@/model/dn-employee-schedule.js";
import { useDataStore } from "@/stores/dataStore.js";
import { useReportStore } from '@/stores/reportStore.js';

/**
 * @typedef {{id?:number;payrollRuleId?:number;workType:number|null;taskTypes?:number[];stMinute:number|null;stDay:number|null;fiMinute:number|null;fiDay:number|null;subtract:boolean|null;shiftBounds:boolean}} PayrollRuleDetailDto
 */

/**
 * @typedef {{id?:number;agreementId?:number;code:string;name:string;kind:number;weekLimitAbove:boolean|null;details?:PayrollRuleDetailDto[]}} PayrollRuleDto
 */

/**
 * @typedef {{id:number;name:string;tagId:number|null;payrollPeriod:number;payrollPeriodShiftBounds:boolean;payrollRules?:PayrollRuleDto[]}} AgreementDto
 */

/**
 * @typedef {{id?:number;name?:string;tagId?:number|null;payrollPeriod?:number;payrollPeriodShiftBounds?:boolean;payrollRules?:PayrollRuleDto[];deletedPayrollRules?:number[]}} SaveAgreementDto
 */

/**
 * @typedef {{empid:string;empName:string;code:string;payrollName:string;amount:number;unit:string}} PayrollReportRow 
 */

/**
 * @typedef {{st:Date;numberOfDays:number}} TimeRange
 */

export const PAYROLL_RULE_KIND = createPayrollRuleKind();

function createPayrollRuleKind() {
  const e = {
    hours: 0,
    dayCount: 1,
    /** @type {(t:(arg0:string) => string) => {id:number;name:string}[]} */
    getOptions: null
  };

  e.getOptions = function (t) {
    return [dd(e.hours, t('settings.hours')), dd(e.dayCount, t('settings.day-count'))];
  }

  return Object.freeze(e);
}

export const PAYROLL_RULE_WORK_TYPE = createPayrollRuleWorkType();

function createPayrollRuleWorkType() {
  const e = {
    paidWork: 0,
    attendance: 1,
    taskTypes: 2,
  };

  return Object.freeze(e);
}

export const PAYROLL_RULE_DAY_KIND = createPayrollRuleDayKind();

function createPayrollRuleDayKind() {
  const e = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    holiday: 7,
    dayBeforeHoliday: 8,
    dayAfterHoliday: 9,
    /** @type {(t:(arg0:string) => string) => {id:number;name:string}[]} */
    getOptions: null
  };

  e.getOptions = function (t) {
    const result = []
    for (let i = 0; i < 7; i++) {
      const dt = new Date(2024, 0, 7 + i);
      result.push(dd(i, dt.toLocaleDateString('en-US', { weekday: 'long' })));
    }
    result.push(dd(e.holiday, t('settings.holiday')));
    result.push(dd(e.dayBeforeHoliday, t('settings.day-before-holiday')));
    result.push(dd(e.dayAfterHoliday, t('settings.day-after-holiday')));

    return result;
  }

  return Object.freeze(e);
}

export function getWeekLimitAboveOptions(t) {
  return [{ id: true, name: 'above' }, { id: false, name: 'below' }];
}

/**
 * 
 * @param {number} id
 * @param {string} name
 */
function dd(id, name) {
  return { id, name };
}

export class Agreements {
  constructor() {
    /** @private @type {Map<number, AgreementDto>} */
    this.byId = null;
    /** @private @type {Map<number, AgreementDto>} */
    this._byTagId = null;
    this.loadCounter = 0;
  }

  get byTagId() {
    if (this._byTagId === null && this.isLoaded) {
      /** @type {Map<number, AgreementDto>} */
      const byTagId = new Map();
      for (const dto of this.byId.values()) {
        byTagId.set(dto.tagId, dto);
      }
      this._byTagId = byTagId;
    }
    return this._byTagId;
  }

  get isLoaded() {
    return this.byId !== null;
  }

  get hasPayrollRules() {
    if (!this.isLoaded) { return undefined; }
    for (const agreement of this.byId.values()) {
      if (agreement.payrollRules.length > 0) {
        return true;
      }
    }
    return false;
  }

  asEditableList() {
    /** @type {EditableAgreement[]} */
    const list = [];
    if (this.byId) {
      for (const dto of this.byId.values()) {
        list.push(new EditableAgreement(dto));
      }
    }

    return list;
  }

  /**
   * @param {number} id
   */
  async delete(id) {
    await fetchAndCheckJson('agreement/' + id, 'DELETE');
    if (this.byId) {
      this.byId.delete(id);
      this._byTagId = null;
    }
  }

  async load() {
    /** @type {AgreementDto[]} */
    const dtoList = await fetchAndCheckJson('agreement', 'GET');
    const byId = new Map();
    for (const dto of dtoList) {
      byId.set(dto.id, dto);
    }
    this.byId = byId;
    this._byTagId = null;
    this.loadCounter += 1;
  }

  /**
   * @param {SaveAgreementDto[]} agreementsToSave
   */
  async save(agreementsToSave) {
    await fetchAndCheckJson('agreement', 'POST', agreementsToSave);
    await this.load();
  }

  /**
   * @private
   * @param {number[]} tagIdList
   * @param {AgreementDto} defaultAgreement
   */
  getByTagId(tagIdList, defaultAgreement = undefined) {
    const byTagId = this.byTagId;
    for (const tagId of tagIdList) {
      const dto = byTagId.get(tagId);
      if (dto) { return dto; }
    }
    if (defaultAgreement === undefined) {
      defaultAgreement = this._byTagId.get(null);
      if (defaultAgreement === undefined) {
        defaultAgreement = null;
      }
    }

    return defaultAgreement;
  }

  /**
   * @param {import("@/model/dn-employee").Employee} employee
   */
  getPayrollPeriod(employee) {
    const dataStore = useDataStore();
    const cc = dataStore.currentCC;
    const defaultAgreement = this.getByTagId(cc.tagIds);
    const agreement = this.getByTagId(employee.tagIds, defaultAgreement);
    return agreement ? agreement.payrollPeriod : 0;
  }

  /**
   * @param {TimeRange} timeRange
   * @param {import("@/model/dn-employee").Employee[]} employees
   * @param {import("@/model/dn-task.js").Task[]} scheduleTasks
   */
  getPayrollReportData(timeRange, employees, scheduleTasks) {
    return this.getPayrollReportDataImpl(timeRange, employees, scheduleTasks).rows;
  }

  /**
   * @param {TimeRange} timeRange
   * @param {import("@/model/dn-employee").Employee[]} employees
   * @param {import("@/model/dn-task.js").Task[]} scheduleTasks
   */
  getPayrollReportDataOneRow(timeRange, employees, scheduleTasks) {
    return this.getPayrollReportDataImpl(timeRange, employees, scheduleTasks).oneRowReport;
  }

  /**
   * @private
   * @param {TimeRange} timeRange
   * @param {import("@/model/dn-employee").Employee[]} employees
   * @param {import("@/model/dn-task.js").Task[]} scheduleTasks
   */
  getPayrollReportDataImpl(timeRange, employees, scheduleTasks) {
    const dataStore = useDataStore();
    const cc = dataStore.currentCC;
    const holidays = dataStore.holidays;
    const taskTypes = dataStore.taskTypes;
    const reportOptions = useReportStore().reportOptions;
    const adherenceBase = dataStore.adherenceOptions.adherenceBase;
    const weeklyLimit = reportOptions.numHoursWeeklyOvertime ? reportOptions.numHoursWeeklyOvertime : 40;
    const sortedHolidays = getSortedHolidays(timeRange, holidays, reportOptions.weekendAsHoliday);
    const timeRangeWeeks = splitTimeRangeInWeeks(dataStore.generalEmpAppSettings.weekStartDay, timeRange);

    const defaultAgreement = this.getByTagId(cc.tagIds);
    /** @type {import("@/model/dn-employee").Employee[]} */
    const employeesWithAgreement = [];
    /** @type {Map<number, PayrollRuleCalcGroup[]>} */
    const payrollRuleGroupsById = new Map();
    /** @type {Map<number, PayrollRuleCalcGroup[]>} */
    const payrollRuleGroupsEmpId = new Map();

    for (const emp of employees) {
      const agreement = this.getByTagId(emp.tagIds, defaultAgreement);
      if (agreement) {
        employeesWithAgreement.push(emp);
        let groups = payrollRuleGroupsById.get(agreement.id);
        if (groups === undefined) {
          groups = [];
          for (const dto of agreement.payrollRules) {
            /** @type {PayrollRuleCalcGroup} */
            let group = undefined;
            if (dto.code.length > 0) {
              group = groups.find(x => x.code === dto.code && x.kind === dto.kind);
            }
            if (!group) {
              group = new PayrollRuleCalcGroup(dto);
              groups.push(group);
            }
            if (dto.weekLimitAbove === null) {
              const payrollRule = new PayrollRuleCalc(taskTypes.byId, dto, timeRange, agreement.payrollPeriodShiftBounds, weeklyLimit, sortedHolidays, adherenceBase);
              group.payrollRules.push(payrollRule);
            } else {
              for (const tr of timeRangeWeeks) {
                const payrollRule = new PayrollRuleCalc(taskTypes.byId, dto, tr, agreement.payrollPeriodShiftBounds, weeklyLimit, sortedHolidays, adherenceBase);
                group.payrollRules.push(payrollRule);
              }
            }
          }
          for (const group of groups) {
            group.calcName();
          }

          payrollRuleGroupsById.set(agreement.id, groups);
        }
        payrollRuleGroupsEmpId.set(emp.id, groups);
      }
    }

    /** @type {import("@/components/Reporting/TableReport.vue").ReportColumnDefinition[]} */
    const reportColumnDefinitions = [];
    for (const rules of payrollRuleGroupsById.values()) {
      for (const r of rules) {
        reportColumnDefinitions.push({ name: r.id.toString(), header: r.code, width: 50 });
      }
    }

    const schedules = createSchedule(timeRange.numberOfDays, timeRange.st, scheduleTasks, taskTypes, employeesWithAgreement, true);
    /** @type {PayrollReportRow[]} */
    const rows = [];
    /** @type {{empid:string;empName:string;[p:string]:any}[]} */
    const rows2 = [];
    /** @type {Map<number, {empid:string;empName:string;[p:string]:any}>} */
    const row2ByEmp = new Map();

    for (const employeeSchedule of schedules) {
      const groups = payrollRuleGroupsEmpId.get(employeeSchedule.emp.id);
      const empid = employeeSchedule.emp.empid;
      const empName = employeeSchedule.emp.name;
      for (const payrollRuleGroup of groups) {
        let amount = 0;
        for (const payrollRule of payrollRuleGroup.payrollRules) {
          amount += payrollRule.getAmount(employeeSchedule)
        }
        if (amount !== 0) {
          amount = Math.round(10000 * amount) / 10000;
          const code = payrollRuleGroup.code;
          const payrollName = payrollRuleGroup.name;
          const unit = payrollRuleGroup.unit;
          rows.push({ empid, empName, code, payrollName, amount, unit });
          let row2 = row2ByEmp.get(employeeSchedule.emp.id);
          if (row2 === undefined) {
            row2 = { empid, empName };
            row2ByEmp.set(employeeSchedule.emp.id, row2)
            rows2.push(row2)
          }
          row2[payrollRuleGroup.id] = amount;
        }
      }
    }

    return { rows, oneRowReport: { reportColumnDefinitions, rows: rows2 } };
  }
}

/**
 * @param {string} [name]
 */
export function createNewAgreement(name) {
  return new EditableAgreement({ id: undefined, name, tagId: null, payrollPeriod: 0, payrollPeriodShiftBounds: false, payrollRules: [] });
}

export class EditableAgreement {
  /**
   * @param {AgreementDto} dto
   */
  constructor(dto) {
    /** @private @type {AgreementDto} */
    this._dto = dto;
    /** @private @type {string} */
    this._name = dto.name;
    /** @private @type {number} */
    this._payrollPeriod = dto.payrollPeriod;
    /** @private @type {boolean} */
    this._payrollPeriodShiftBounds = dto.payrollPeriodShiftBounds;
    /** @private @type {number|null} */
    this._tagId = dto.tagId;
    /** @private @type {EditablePayrollRule[]} */
    this._payrollRules = dto.payrollRules.map(x => new EditablePayrollRule(this, x));
    /** @private @type {boolean} */
    this._hasChanges = false;
    /** @private @type {number[]} */
    this.deletedPayrollRules = [];
    /** @private @type {number} */
    this._lastNewPayrollId = 0;
  }

  get id() {
    return this._dto.id;
  }

  get oldName() {
    return this._dto.name;
  }

  get name() {
    return this._name;
  }
  set name(value) {
    this._name = value;
    this.setHasChanges();
  }

  get payrollPeriod() {
    return this._payrollPeriod;
  }
  set payrollPeriod(value) {
    this._payrollPeriod = value;
    this.setHasChanges();
  }

  get payrollPeriodShiftBounds() {
    return this._payrollPeriodShiftBounds;
  }
  set payrollPeriodShiftBounds(value) {
    this._payrollPeriodShiftBounds = value;
    this.setHasChanges();
  }

  get payrollRules() {
    return this._payrollRules;
  }

  get tagId() {
    return this._tagId;
  }
  set tagId(value) {
    this._tagId = value;
    this.setHasChanges();
  }

  get hasChanges() {
    return this._hasChanges;
  }

  addPayrollRule() {
    this._lastNewPayrollId -= 1;
    const payrollRule = new EditablePayrollRule(this, { id: this._lastNewPayrollId,
      code: '', name: '', kind: PAYROLL_RULE_KIND.hours, weekLimitAbove: null, details: []
    });
    payrollRule.addDetail();
    this.payrollRules.push(payrollRule);
  }

  /** @private */
  calcHasChanges() {
    if (this.id === undefined) { return true; }
    if (this.deletedPayrollRules.length > 0) { return true; }
    if (this.name !== this._dto.name) { return true; }
    if (this.payrollPeriod !== this._dto.payrollPeriod) { return true; }
    if (this.payrollPeriodShiftBounds !== this._dto.payrollPeriodShiftBounds) { return true; }
    if (this.tagId !== this._dto.tagId) { return true; }
    return this._payrollRules.some(x => x.hasChanges);
  }

  /**
   * @param {EditablePayrollRule} rule
   */
  deletePayrollRule(rule) {
    if (rule.id > 0) {
      this.deletedPayrollRules.push(rule.id);
    }
    const index = this.payrollRules.findIndex(x => x.id === rule.id);
    this.payrollRules.splice(index, 1);
    this.setHasChanges();
  }

  setHasChanges() {
    this._hasChanges = this.calcHasChanges();
  }

  toSaveDto() {
    /** @type {PayrollRuleDto[]} */
    const payrollRules = [];
    for (const payrollRule of this.payrollRules) {
      if (payrollRule.hasChanges) {
        payrollRules.push(payrollRule.toDto());
      }
    }

    /** @type {SaveAgreementDto} */
    const dto = { id: this.id, deletedPayrollRules: this.deletedPayrollRules, payrollRules };
    if (this.name !== this._dto.name) { dto.name = this.name; }
    if (this.payrollPeriod !== this._dto.payrollPeriod) { dto.payrollPeriod = this.payrollPeriod; }
    if (this.payrollPeriodShiftBounds !== this._dto.payrollPeriodShiftBounds) { dto.payrollPeriodShiftBounds = this.payrollPeriodShiftBounds; }
    if (this.tagId !== this._dto.tagId) { dto.tagId = this.tagId; }
    return dto;
  }
}

export class EditablePayrollRule {
  /**
   * @param {EditableAgreement} parent
   * @param {PayrollRuleDto} dto
   */
  constructor(parent, dto) {
    /** @private @type {EditableAgreement}  */
    this.parent = parent;
    /** @private @type {PayrollRuleDto} */
    this.dto = dto;
    /** @private @type {string} */
    this._code = dto.code;
    /** @private @type {number} */
    this._kind = dto.kind;
    /** @private @type {string} */
    this._name = dto.name;
    /** @private @type {boolean} */
    this._weekLimitAbove = dto.weekLimitAbove;
    /** @private @type {boolean} */
    this._hasChanges = false;
    /** @type {EditablePayrollRuleDetail[]} */
    this.details = dto.details.map(x => new EditablePayrollRuleDetail(this, x));
    /** @type {number} */
    this._lastNewDetailId = 0;
  }

  get id() {
    return this.dto.id;
  }

  get code() {
    return this._code;
  }
  set code(value) {
    this._code = value;
    this.setHasChanges();
  }

  get kind() {
    return this._kind;
  }
  set kind(value) {
    this._kind = value;
    this.setHasChanges();
  }

  get name() {
    return this._name;
  }
  set name(value) {
    this._name = value;
    this.setHasChanges();
  }

  get weekLimitAbove() {
    return this._weekLimitAbove;
  }
  set weekLimitAbove(value) {
    this._weekLimitAbove = value;
    this.setHasChanges();
  }

  get hasChanges() {
    return this._hasChanges;
  }

  addDetail() {
    this._lastNewDetailId -= 1;
    const dto = {
      id: this._lastNewDetailId, workType: PAYROLL_RULE_WORK_TYPE.paidWork,
      taskTypes: [], shiftBounds: false, subtract: false, stMinute: 0, stDay: null, fiMinute: 0, fiDay: null
    };
    this.details.push(new EditablePayrollRuleDetail(this, dto));
    this.setHasChanges();
  }

  /** @private */
  calcHasChanges() {
    if (this.id < 0) { return true; }
    if (this.code !== this.dto.code) { return true; }
    if (this.kind !== this.dto.kind) { return true; }
    if (this.name !== this.dto.name) { return true; }
    if (this.weekLimitAbove !== this.dto.weekLimitAbove) { return true; }
    if (this.details.length !== this.dto.details.length) { return true; }
    if (this.details.some(x => x.hasChanges)) { return true; }
    return false;
  }

  /**
   * @param {EditablePayrollRuleDetail} detail
   */
  deleteDetail(detail) {
    const index = this.details.findIndex(x => x.id === detail.id);
    this.details.splice(index, 1);
    this.setHasChanges();
  }

  setHasChanges() {
    this._hasChanges = this.calcHasChanges();
    this.parent.setHasChanges();
  }

  toDelete() {
    this.parent.deletePayrollRule(this);
  }

  /** @returns {PayrollRuleDto} */
  toDto() {
    /** @type {PayrollRuleDto} */
    const dto = {
      code: this.code, kind: this.kind, name: this.name,
      weekLimitAbove: this.weekLimitAbove,
    };
    dto.details = this.details.map(x => x.toDto());
    if (this.id > 0) { dto.id = this.id; }
    return dto;
  }
}

class EditablePayrollRuleDetail {
  /**
   * @param {EditablePayrollRule} parent
   * @param {PayrollRuleDetailDto} dto
   */
  constructor(parent, dto) {
    /** @private @type {EditablePayrollRule}  */
    this.parent = parent;
    /** @private @type {PayrollRuleDetailDto} */
    this.dto = dto;
    /** @private @type {number} */
    this._fiDay = dto.fiDay;
    /** @private @type {number} */
    this._fiMinute = dto.fiMinute;
    /** @private @type {number} */
    this._stDay = dto.stDay;
    /** @private @type {number} */
    this._stMinute = dto.stMinute;
    /** @private @type {boolean} */
    this._shiftBounds = dto.shiftBounds;
    /** @private @type {boolean} */
    this._subtract = dto.subtract;
    /** @private @type {number[]} */
    this._taskTypes = dto.taskTypes.slice();
    /** @private @type {number} */
    this._workType = dto.workType;
    /** @private @type {boolean} */
    this._hasChanges = false;
  }

  get id() {
    return this.dto.id;
  }

  get fiDay() {
    return this._fiDay;
  }
  set fiDay(value) {
    this._fiDay = value;
    this.setHasChanges();
  }

  get fiTime() {
    return minutesToDate(this._fiMinute);
  }
  set fiTime(value) {
    this._fiMinute = dateToMinutes(value);
    this.setHasChanges();
  }

  get shiftBounds() {
    return this._shiftBounds;
  }
  set shiftBounds(value) {
    this._shiftBounds = value;
    this.setHasChanges();
  }

  get stDay() {
    return this._stDay;
  }
  set stDay(value) {
    this._stDay = value;
    this.setHasChanges();
  }

  get stTime() {
    return minutesToDate(this._stMinute);
  }
  set stTime(value) {
    this._stMinute = dateToMinutes(value);
    this.setHasChanges();
  }

  get subtract() {
    return this._subtract;
  }
  set subtract(value) {
    this._subtract = value;
    this.setHasChanges();
  }

  get workTypes() {
    if (this._workType !== PAYROLL_RULE_WORK_TYPE.taskTypes) {
      return [-this._workType];
    }
    return this._taskTypes;
  }
  set workTypes(value) {
    if (value.length === 0) {
      this._workType = PAYROLL_RULE_WORK_TYPE.paidWork;
      this._taskTypes = [];
    } else {
      /** @type {Set<number>} */
      const selectedWorkTypes = new Set();
      /** @type {number[]} */
      const taskTypes = [];
      for (const id of value) {
        if (id > 0) {
          taskTypes.push(id);
          selectedWorkTypes.add(PAYROLL_RULE_WORK_TYPE.taskTypes);
        } else {
          selectedWorkTypes.add(-id);
        }
      }
      if (selectedWorkTypes.size === 3) {
        if (this._taskTypes.length === taskTypes.length) {
          this._workType = PAYROLL_RULE_WORK_TYPE.paidWork;
        } else {
          this._workType = PAYROLL_RULE_WORK_TYPE.taskTypes;
        }
      } else {
        if (selectedWorkTypes.size > 1) {
          selectedWorkTypes.delete(this._workType);
        }
        for (const workType of selectedWorkTypes.values()) {
          this._workType = workType;
        }
      }

      if (this._workType === PAYROLL_RULE_WORK_TYPE.taskTypes) {
        taskTypes.sort();
        this._taskTypes = taskTypes;
      } else {
        this._taskTypes = [];
      }
    }

    this.setHasChanges();
  }

  get hasChanges() {
    return this._hasChanges;
  }

  /** @private */
  calcHasChanges() {
    if (this.dto.id < 0) { return true; }
    if (this.fiDay !== this.dto.fiDay) { return true; }
    if (this._fiMinute !== this.dto.fiMinute) { return true; }
    if (this.stDay !== this.dto.stDay) { return true; }
    if (this._stMinute !== this.dto.stMinute) { return true; }
    if (this._shiftBounds !== this.dto.shiftBounds) { return true; }
    if (this._subtract !== this.dto.subtract) { return true; }
    if (this._workType !== this.dto.workType) { return true; }
    const taskTypes = this._taskTypes;
    if (taskTypes.length !== this.dto.taskTypes.length) { return true; }
    for (let i = 0; i < taskTypes.length; i++) {
      if (taskTypes[i] !== this.dto.taskTypes[i]) {
        return true;
      }
    }
    return false;
  }

  /** @private */
  setHasChanges() {
    this._hasChanges = this.calcHasChanges();
    this.parent.setHasChanges();
  }

  toDto() {
    /** @type {PayrollRuleDetailDto} */
    const dto = {
      fiDay: this.fiDay, fiMinute: this._fiMinute, stDay: this.stDay, stMinute: this._stMinute, shiftBounds: this.shiftBounds,
      subtract: this.subtract, taskTypes: this._taskTypes, workType: this._workType
    };
    if (this.dto.id > 0) { dto.id = this.dto.id; }
    return dto;
  }
}

class PayrollRuleCalc {
  /**
   * @param {Map<number, import("@/model/dn-tasktype.js").TaskType>} taskTypeById
   * @param {PayrollRuleDto} payrollRule
   * @param {TimeRange} timeRange
   * @param {boolean} payrollPeriodShiftBounds
   * @param {number} weeklyLimit
   * @param {Date[]} sortedHolidays
   * @param {number} adherenceBase
   */
  constructor(taskTypeById, payrollRule, timeRange, payrollPeriodShiftBounds, weeklyLimit, sortedHolidays, adherenceBase) {
    /** @type {string} */
    this.code = payrollRule.code;
    /** @type {string} */
    this.name = payrollRule.name;
    /** @type {number} */
    this.weeklyLimit = weeklyLimit;
    /** @type {boolean} */
    this.weeklyLimitAbove = payrollRule.weekLimitAbove;
    const unitDays = payrollRule.kind === PAYROLL_RULE_KIND.dayCount;
    const useShiftBounds = payrollRule.kind === PAYROLL_RULE_KIND.dayCount || payrollPeriodShiftBounds;

    /** @type {PayrollRuleDetail[]} */
    this.details = [];
    for (const detail of payrollRule.details) {
      if (detail.shiftBounds) {
        this.details.push(new PayrollRuleShiftBound(detail, taskTypeById, timeRange,
          sortedHolidays, adherenceBase, unitDays));
      } else {
        this.details.push(new PayrollRuleTime(detail, taskTypeById, timeRange,
          sortedHolidays, adherenceBase, unitDays, useShiftBounds));
      }
    }
  }

  /**
   * @param {import("@/model/dn-employee-schedule.js").EmployeeSchedule} employeeSchedule
   */
  getAmount(employeeSchedule) {
    let amount = 0;
    for (const d of this.details) {
      amount += d.getAmount(employeeSchedule);
    }
    if (this.weeklyLimitAbove === true) {
      amount = Math.max(0, amount - this.weeklyLimit);
    } else if (this.weeklyLimitAbove === false) {
      amount = Math.min(amount, this.weeklyLimit);
    }
    return amount;
  }
}

class PayrollRuleDetail {
  /**
   * @param {PayrollRuleDetailDto} payrollRule
   * @param {Map<number, import("@/model/dn-tasktype.js").TaskType>} taskTypeById
   * @param {TimeRange} timeRange
   * @param {boolean} unitDays
   * @param {number} adherenceBase
   */
  constructor(payrollRule, taskTypeById, timeRange, unitDays, adherenceBase) {
    /** @type {number} */
    this.adherenceBase = adherenceBase;
    /** @type {boolean} */
    this.calcAttendance = payrollRule.workType === PAYROLL_RULE_WORK_TYPE.attendance;
    /** @readonly @private @type {boolean} */
    this.subtract = payrollRule.subtract;
    /** @type {Map<number, Set<number>>} */
    this.taskTypesByKind = new Map();
    /** @type {boolean} */
    this.unitDays = unitDays;
    for (const taskTypeId of payrollRule.taskTypes) {
      const tt = taskTypeById.get(taskTypeId);
      let ttSet = this.taskTypesByKind.get(tt.kind);
      if (ttSet === undefined) {
        ttSet = new Set();
        this.taskTypesByKind.set(tt.kind, ttSet);
      }
      ttSet.add(tt.id);
    }
    /** @type {TimeRange} */
    this.timeRange = timeRange;
  }

  /**
   * @protected
   * @param {import("@/model/dn-employee-schedule.js").EmployeeSchedule} employeeSchedule
   */
  getInternalAmount(employeeSchedule) {
    return 0;
  }

  /**
   * @param {import("@/model/dn-employee-schedule.js").EmployeeSchedule} employeeSchedule
   */
  getAmount(employeeSchedule) {
    let amount = this.getInternalAmount(employeeSchedule);
    if (this.subtract) {
      return -amount;
    }
    return amount;
  }
}

class PayrollRuleTime extends PayrollRuleDetail {
  /**
   * @param {PayrollRuleDetailDto} payrollRule
   * @param {Map<number, import("@/model/dn-tasktype.js").TaskType>} taskTypeById
   * @param {TimeRange} timeRange
   * @param {Date[]} sortedHolidays
   * @param {number} adherenceBase
   * @param {boolean} unitDays
   * @param {boolean} useShiftBounds
   */
  constructor(payrollRule, taskTypeById, timeRange, sortedHolidays, adherenceBase, unitDays, useShiftBounds) {
    super(payrollRule, taskTypeById, timeRange, unitDays, adherenceBase)
    const minutesPerDay = 24 * 60;

    /** @type {boolean} */
    this.useShiftBounds = useShiftBounds;
    /** @readonly @type {{st:Date;fi:Date}[]} */
    this.timeToRemove = [];
    if (!this.useShiftBounds) {
      this.addTimeToRemove(addDays(timeRange.st, -1000), timeRange.st);
      const fi = addDays(timeRange.st, timeRange.numberOfDays);
      this.addTimeToRemove(fi, addDays(fi, 1000));
    }
    if (payrollRule.stDay !== null && payrollRule.fiDay !== null) {
      let stMinute = payrollRule.stMinute !== null ? payrollRule.stMinute : 0;
      let fiMinute = payrollRule.fiMinute !== null ? payrollRule.fiMinute : minutesPerDay;

      if (payrollRule.stDay < 7) {
        let numberOfDays = timeRange.numberOfDays + 1;
        /** @type {Date} */
        let st = null;
        for (let i = -7; i < numberOfDays; i++) {
          const dt = addDays(timeRange.st, i);
          const day = dt.getDay();
          if (st === null && payrollRule.fiDay === day) {
            st = new Date(dt);
            st.setMinutes(fiMinute);
          }
          if (st !== null && payrollRule.stDay === day) {
            const fi = new Date(dt);
            fi.setMinutes(stMinute);
            if (st < fi) {
              this.addTimeToRemove(st, fi);
              st = null;
            }
          }
          if (st === null && payrollRule.fiDay === day) {
            st = new Date(dt);
            st.setMinutes(fiMinute);
          }
        }
        if (st !== null) {
          this.addTimeToRemove(st, addDays(timeRange.st, numberOfDays + 7));
        }
      } else {
        if (payrollRule.stDay === PAYROLL_RULE_DAY_KIND.dayBeforeHoliday) {
          stMinute -= minutesPerDay;
        } else if (payrollRule.stDay === PAYROLL_RULE_DAY_KIND.dayAfterHoliday) {
          stMinute += minutesPerDay;
        }
        if (payrollRule.fiDay === PAYROLL_RULE_DAY_KIND.dayBeforeHoliday) {
          fiMinute -= minutesPerDay;
        } else if (payrollRule.fiDay === PAYROLL_RULE_DAY_KIND.dayAfterHoliday) {
          fiMinute += minutesPerDay;
        }
        if (stMinute < fiMinute) {
          let st = addDays(timeRange.st, -7);
          let fi = null;
          for (const holiday of sortedHolidays) {
            fi = new Date(holiday);
            fi.setMinutes(stMinute);
            if (st < fi) {
              this.addTimeToRemove(st, fi);
            }
            st = new Date(holiday)
            st.setMinutes(fiMinute);
          }
          fi = addDays(timeRange.st, timeRange.numberOfDays + 7);
          this.addTimeToRemove(st, fi);
        }
      }
    } else if (payrollRule.stMinute !== null && payrollRule.fiMinute !== null) {
      let dt = addDays(timeRange.st, -1);
      let numberOfDays = timeRange.numberOfDays + 2;
      if (payrollRule.stMinute > 0) {
        const fi = new Date(dt);
        fi.setMinutes(payrollRule.stMinute);
        this.addTimeToRemove(dt, fi);
      }
      let stMinute = payrollRule.stMinute;
      let fiMinute = payrollRule.fiMinute;
      if (fiMinute <= stMinute) {
        dt = timeRange.st;
        numberOfDays -= 1;
      } else {
        stMinute += minutesPerDay;
      }
      for (let i = 0; i < numberOfDays; i++) {
        const st = new Date(dt);
        st.setMinutes(fiMinute);
        const fi = new Date(dt);
        fi.setMinutes(stMinute);
        this.addTimeToRemove(st, fi);
        dt = addDays(dt, 1);
      }
    }
  }

  /**
   * @private
   * @param {Date} st
   * @param {Date} fi
   */
  addTimeToRemove(st, fi) {
    if (st < fi) {
      this.timeToRemove.push({ st, fi });
    }
  }

  /**
   * @param {import("@/model/dn-employee-schedule.js").EmployeeSchedule} employeeSchedule
   */
  getInternalAmount(employeeSchedule) {
    return employeeSchedule.getPayrollTime(this);
  }
}

class PayrollRuleShiftBound extends PayrollRuleDetail {
  /**
   * @param {PayrollRuleDetailDto} payrollRule
   * @param {Map<number, import("@/model/dn-tasktype.js").TaskType>} taskTypeById
   * @param {TimeRange} timeRange
   * @param {Date[]} sortedHolidays
   * @param {number} adherenceBase
   * @param {boolean} unitDays
   */
  constructor(payrollRule, taskTypeById, timeRange, sortedHolidays, adherenceBase, unitDays) {
    super(payrollRule, taskTypeById, timeRange, unitDays, adherenceBase)
    const minutesPerDay = 24 * 60;
    const timeRangeFi = addDays(timeRange.st, timeRange.numberOfDays);

    /** @type {{st:Date;fi:Date}[]} */
    this.timeIntervals = [];
    if (payrollRule.stDay !== null && payrollRule.fiDay !== null) {
      let stMinute = payrollRule.stMinute !== null ? payrollRule.stMinute : 0;
      let fiMinute = payrollRule.fiMinute !== null ? payrollRule.fiMinute : minutesPerDay;
      if (payrollRule.stDay < 7) {
        let numberOfDays = timeRange.numberOfDays + 1;
        /** @type {Date} */
        let st = null;
        for (let i = -7; i < numberOfDays; i++) {
          const dt = addDays(timeRange.st, i);
          const day = dt.getDay();
          if (st === null && payrollRule.stDay === day) {
            st = new Date(dt);
            st.setMinutes(stMinute);
            if (st < timeRange.st) { st = timeRange.st; }
          }
          if (st !== null && payrollRule.fiDay === day) {
            let fi = new Date(dt);
            fi.setMinutes(fiMinute);
            if (fi > timeRangeFi) { fi = timeRangeFi; }
            this.addtimeIntervals(st, fi);
            st = null;
          }
          if (st === null && payrollRule.stDay === day) {
            st = new Date(dt);
            st.setMinutes(stMinute);
            if (st < timeRange.st) { st = timeRange.st; }
          }
        }
        if (st !== null) {
          this.addtimeIntervals(st, timeRangeFi);
        }
      } else {
        if (payrollRule.stDay === PAYROLL_RULE_DAY_KIND.dayBeforeHoliday) {
          stMinute -= minutesPerDay;
        } else if (payrollRule.stDay === PAYROLL_RULE_DAY_KIND.dayAfterHoliday) {
          stMinute += minutesPerDay;
        }
        if (payrollRule.fiDay === PAYROLL_RULE_DAY_KIND.dayBeforeHoliday) {
          fiMinute -= minutesPerDay;
        } else if (payrollRule.fiDay === PAYROLL_RULE_DAY_KIND.dayAfterHoliday) {
          fiMinute += minutesPerDay;
        }
        if (stMinute < fiMinute) {
          /** @type {Date} */
          let st = null;
          /** @type {Date} */
          let fi = null;
          for (const holiday of sortedHolidays) {
            let newSt = new Date(holiday);
            newSt.setMinutes(stMinute);
            if (newSt < timeRange.st) { newSt = timeRange.st; }
            if (fi) {
              if (fi < newSt) {
                this.addtimeIntervals(st, fi);
                st = newSt;
              }
            } else {
              st = newSt;
            }
            fi = new Date(holiday);
            fi.setMinutes(fiMinute);
            if (fi > timeRangeFi) { fi = timeRangeFi; }
          }

          if (fi) {
            this.addtimeIntervals(st, fi);
          }
        }
      }
    } else if (payrollRule.stMinute !== null && payrollRule.fiMinute !== null && payrollRule.stMinute !== payrollRule.fiMinute) {
      const stMinute = payrollRule.stMinute;
      const fiMinute = payrollRule.fiMinute;
      const fiNextDay = fiMinute < stMinute;
      if (fiNextDay) {
        const fi = new Date(timeRange.st);
        fi.setMinutes(fiMinute);
        this.addtimeIntervals(timeRange.st, fi);
      }
      const lastIndex = timeRange.numberOfDays - 1;
      for (let i = 0; i < timeRange.numberOfDays; i++) {
        const st = addDays(timeRange.st, i);
        const fi = fiNextDay ? addDays(st, 1) : new Date(st);
        st.setMinutes(stMinute);
        if (!fiNextDay || lastIndex !== i) {
          fi.setMinutes(fiMinute);
        }
        this.addtimeIntervals(st, fi);
      }
    } else {
      this.addtimeIntervals(timeRange.st, timeRangeFi);
    }
  }

  /**
   * @private
   * @param {Date} st
   * @param {Date} fi
   */
  addtimeIntervals(st, fi) {
    if (st < fi) {
      this.timeIntervals.push({ st, fi });
    }
  }

  /**
   * @param {import("@/model/dn-employee-schedule.js").EmployeeSchedule} employeeSchedule
   */
  getInternalAmount(employeeSchedule) {
    return employeeSchedule.getPayrollShiftBound(this);
  }
}

class PayrollRuleCalcGroup {
  /**
   * @param {PayrollRuleDto} payrollRule
   */
  constructor(payrollRule) {
    /** @readonly @type {number} */
    this.id = payrollRule.id;
    /** @readonly @type {string} */
    this.code = payrollRule.code;
    /** @type {string} */
    this.name = payrollRule.name;
    /** @readonly @type {number} */
    this.kind = payrollRule.kind;
    /** @readonly @type {string} */
    this.unit = payrollRule.kind === PAYROLL_RULE_KIND.dayCount ? 'days' : 'hours';
    /** @readonly @type {PayrollRuleCalc[]} */
    this.payrollRules = [];
  }

  calcName() {
    if (this.payrollRules.length <= 1) { return; }
    let name = this.name;
    for (const r of this.payrollRules) {
      if (name.length === 0 || r.name.startsWith(name)) { continue; }
      const length = Math.min(name.length, r.name.length)
      let count = 0;
      let newlength = 0;
      for (let i = 0; i < length; i++) {
        if (name[i] !== r.name[i]) { break; }
        if (name[i] === ' ') { newlength = count; }
        count += 1;
      }
      if (newlength !== length) {
        name = name.substring(0, newlength);
      }
    }

    if (name.length > 0) {
      this.name = name;
    } else {
      this.name = this.code;
    }
  }
}

/**
 * Get start time of holidays near the timerange
 * @param {{ st: Date; numberOfDays: number; }} timeRange
 * @param {{ date: Date; }[]} holidays
 * @param {boolean} weekendAsHoliday
 */
function getSortedHolidays(timeRange, holidays, weekendAsHoliday) {
  const st = addDays(timeRange.st, -1);
  const fi = addDays(timeRange.st, timeRange.numberOfDays + 1);
  /** @type {Date[]} */
  const sortedHolidays = [];
  for (const holiday of holidays) {
    const dt = holiday.date;
    if (dt >= st && dt < fi) {
      sortedHolidays.push(dt);
    }
  }
  if (weekendAsHoliday) {
    /** @type {Set<number>} */
    const uniqueDays = new Set();
    for (const h of sortedHolidays) {
      uniqueDays.add(h.getTime());
    }

    let dt = st;
    while (dt < fi) {
      const day = dt.getDay();
      if ((day === 0 || day === 6) && !uniqueDays.has(dt.getTime())) {
        sortedHolidays.push(dt);
      }
      dt = addDays(dt, 1);
    }
  }

  sortedHolidays.sort((a, b) => a.getTime() - b.getTime());
  return sortedHolidays;
}

/**
* @param {number} minutes
*/
function minutesToDate(minutes) {
  if (!minutes) { minutes = 0; }
  return new Date(2030, 1, 1, 0, minutes);
}

/**
 * @param {Date} time
 */
function dateToMinutes(time) {
  return time.getHours() * 60 + time.getMinutes();
}

/**
 * @param {number} weekStartDay
 * @param {TimeRange} timeRange
 */
function splitTimeRangeInWeeks(weekStartDay, timeRange) {
  /** @type {TimeRange[]} */
  const weeks = [];
  let st = timeRange.st;
  let day = timeRange.st.getDay();
  let numberOfDays = 0;
  for (let i = 0; i < timeRange.numberOfDays; i++) {
    day += 1;
    if (day > 6) { day = 0; }
    numberOfDays += 1;
    if (day === weekStartDay) {
      weeks.push({ st, numberOfDays });
      st = addDays(timeRange.st, i + 1);
      numberOfDays = 0;
    }
  }
  if (numberOfDays > 0) {
    weeks.push({ st, numberOfDays });
  }

  return weeks;
}