import { LoginInfo } from '@/model/dn-login.js';

const rememberCredentialsKey = 'rememberCredentials'
const credentialsKey = 'credentials'
const simulationSettingsKey = 'simulationSettings'
const lastForcastOptionsKey = 'lastForcastOptionsKey'
const lastScheduleOptionsKey = 'lastScheduleOptionsKey'
const lastAgentScheduleOptionsKey = 'lastAgentScheduleOptionsKey'
const lastSettingsOptionsKey = 'lastSettingsOptionsKey'
const scheduleColumnIdsKey = 'scheduleColumnIdsKey'
const languageKey = 'languageKey'
const loginInfoKey = 'loginKey';
const currentCCKey = 'currentCCId';
const lastSortOptionKey = 'lastSortOptionKey';
const requestFilterKey = 'requestFilterKey';
const employeeDisplaySettingsKey= 'employeeDisplaySettingsKey';
export const TABLE_COLUMN_SCHEDULE_KEY= 'dn-state-schtabel-local'

/** @type {null|LoginInfo} */
let _loginInfo = null;

/** @type {null|Boolean} */
let _rememberCredentials = null;

/** @type {null|number} */
let _currentCCId = null;

export function clearLocalStorage() {
  localStorage.clear();
}

export function getCurrentCCId() {
  if (_currentCCId == null) {
    const id = localStorage.getItem(currentCCKey);
    if (id != null) {
      _currentCCId = parseInt(id);
    }
  }

  return _currentCCId;
}

/**
 * @param {number} id
 */
export function setCurrentCCId(id) {
  _currentCCId = id;
  if (getRememberCredentials())
    localStorage.setItem(currentCCKey, id.toString());
}

export function removeCurrentCCId() {
  _currentCCId = null;
  localStorage.removeItem(currentCCKey);
}

export function getLoginInfo() {
  if (_loginInfo === null) {
    const loginInfo = getJSONFromLocalStorage(loginInfoKey);
    if (loginInfo !== null) {
      _loginInfo = new LoginInfo(loginInfo);
    }
  }
  return _loginInfo;
}

export function removeLoginInfo() {
  window.localStorage.removeItem(loginInfoKey);
  _loginInfo = null;
}

/**
 * @param {import("../model/dn-login").LoginInfo} loginInfo
 */
export function setLoginInfo(loginInfo) {
  if (getRememberCredentials() && loginInfo.customerSchema.length > 0)
    localStorage.setItem(loginInfoKey, JSON.stringify(loginInfo.toStore()));
  _loginInfo = loginInfo;
}

//Simulation settings
export function getSimulationSettings() {
  return getJSONFromLocalStorage(simulationSettingsKey);
}
export function setSimulationSettings(simulationSettings) {
  localStorage.setItem(simulationSettingsKey, JSON.stringify(simulationSettings));
}
export function removeSimulationSettings() {
  localStorage.removeItem(simulationSettingsKey);
}

//Remeber Credentials (checkbox)
export function getRememberCredentials() {
  if (_rememberCredentials === null) {
    const value = localStorage.getItem(rememberCredentialsKey);
    if(value=== null){ _rememberCredentials = false; }
    else{ _rememberCredentials = value === 'true';}
  }

  return _rememberCredentials;
}
/**
 * @param {Boolean} rememberCredentials
 */
export function setRememberCredentials(rememberCredentials) {
  _rememberCredentials = rememberCredentials;
  localStorage.setItem(rememberCredentialsKey, rememberCredentials.toString());
}

//Credentials
export function getCredentials() {
  return getJSONFromLocalStorage(credentialsKey);
}

export function removeCredentials() {
  localStorage.removeItem(credentialsKey);
}

//LastForcastOptions
export function getLastForcastOptions() {
  return getJSONFromLocalStorage(lastForcastOptionsKey);
}

export function setLastForcastOptions(lastForcastOptions) {
  localStorage.setItem(lastForcastOptionsKey, JSON.stringify(lastForcastOptions));
}

//LastScheduleOptions
export function getLastScheduleOptions() {
  return getJSONFromLocalStorage(lastScheduleOptionsKey);
}
export function setLastScheduleOptions(lastScheduleOptions) {
  localStorage.setItem(lastScheduleOptionsKey, JSON.stringify(lastScheduleOptions));
}

//LastAgentScheduleOptions
export function getLastAgentScheduleOptions() {
  return getJSONFromLocalStorage(lastAgentScheduleOptionsKey);
}
export function setLastAgentScheduleOptions(lastAgentScheduleOptions) {
  localStorage.setItem(lastAgentScheduleOptionsKey, JSON.stringify(lastAgentScheduleOptions));
}

//LastSettingsOptions
export function getLastSettingsOptions() {
  return getJSONFromLocalStorage(lastSettingsOptionsKey);
}
export function setLastSettingsOptions(lastSettingsOptions) {
  localStorage.setItem(lastSettingsOptionsKey, JSON.stringify(lastSettingsOptions));
}

//requestFilterOptions
export function getRequestFilterOptions() {
  return getJSONFromLocalStorage(requestFilterKey);
}
export function setRequestFilterOptions(RequestFilterOptions) {
  localStorage.setItem(requestFilterKey, JSON.stringify(RequestFilterOptions));
}

//employeeDisplaySettings
export function getEmployeeDisplaySettings() {
  return getJSONFromLocalStorage(employeeDisplaySettingsKey);
}
export function setEmployeeDisplaySettings(employeeDisplaySettings) {
  localStorage.setItem(employeeDisplaySettingsKey, JSON.stringify(employeeDisplaySettings));
}

/** @returns {number[]} */
export function getScheduleColumnIds() {
  return getJSONFromLocalStorage(scheduleColumnIdsKey);
}
/**
 * @param {number[]} scheduleColumnIds
 */
export function setScheduleColumnIds(scheduleColumnIds) {
  localStorage.setItem(scheduleColumnIdsKey, JSON.stringify(scheduleColumnIds));
}

//Language
export function getLanguage() {
  return getJSONFromLocalStorage(languageKey);
}
export function setLanguage(language) {
  localStorage.setItem(languageKey, JSON.stringify(language));
}
export function removeLanguage() {
  localStorage.removeItem(languageKey);
}

//lastSortOption
export function getLastSortOption() {
  return getJSONFromLocalStorage(lastSortOptionKey);
}
export function setLastSortOption(lastSortOption) {
  localStorage.setItem(lastSortOptionKey, JSON.stringify(lastSortOption));
}

export function removeScheduleColumnsWidths() {
  localStorage.removeItem(TABLE_COLUMN_SCHEDULE_KEY);
}

/**
 * @param {string} key
 */
function getJSONFromLocalStorage(key) {
  const value = localStorage.getItem(key);
  if (value === null)
    return null;
  return JSON.parse(value)
}