import { createApp } from 'vue/dist/vue.esm-bundler.js';
import App from "./App.vue";
import { createAppRouter } from '@/router.js';
import { createPinia } from 'pinia';
import Buefy from "buefy";
import { i18n } from '@/i18n.js';

//Primevue
import PrimeVue from 'primevue/config';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import AutoComplete from 'primevue/autocomplete';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import FloatLabel from 'primevue/floatlabel';
import IconField from 'primevue/iconfield';
import InlineMessage from 'primevue/inlinemessage';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import InputIcon from 'primevue/inputicon';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Listbox from 'primevue/listbox';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import Panel from 'primevue/panel';
import Password from 'primevue/password';
import ProgressBar from 'primevue/progressbar';
import RadioButton from 'primevue/radiobutton';
import ScrollPanel from 'primevue/scrollpanel';
import ContextMenu from 'primevue/contextmenu';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';


import SelectButton from 'primevue/selectbutton';
import Slider from 'primevue/slider';
import SplitButton from 'primevue/splitbutton';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Tag from 'primevue/tag';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import TreeTable from 'primevue/treetable';
import OverlayPanel from 'primevue/overlaypanel';
import Rating from 'primevue/rating';
import TieredMenu from 'primevue/tieredmenu';

import '@/assets/themes/lara-light-purple/theme.css'
//import 'primevue/resources/themes/lara-light-purple/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

const app = createApp(App);

const pinia = createPinia();
app.use(pinia);
app.use(i18n);
app.use(Buefy);
app.use(PrimeVue, {});
app.use(ToastService)

//PrimeVue components
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Checkbox', Checkbox);
app.component('Column', Column);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('FileUpload', FileUpload);
app.component('IconField', IconField);
app.component('InlineMessage', InlineMessage);
app.component('InputGroup', InputGroup);
app.component('InputGroupAddon', InputGroupAddon);
app.component('InputIcon', InputIcon);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText)
app.component('Listbox', Listbox)

app.component('MultiSelect', MultiSelect);
app.component('Panel', Panel);
app.component('Password', Password);
app.component('ProgressBar', ProgressBar);
app.component('PvButton', Button);
app.component('PvDialog', Dialog);
app.component('PvDropdown', Dropdown);
app.component('PvFloatLabel', FloatLabel);
app.component('PvMessage', Message);
app.component('PvSelectButton', SelectButton);
app.component('RadioButton', RadioButton);
app.component('ScrollPanel', ScrollPanel);
app.component('SelectButton', SelectButton);
app.component('Slider', Slider);
app.component('SplitButton', SplitButton);
app.component('Splitter', Splitter);
app.component('SplitterPanel', SplitterPanel);

app.component('TabPanel', TabPanel);
app.component('TabView', TabView);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('Toast', Toast);
app.component('TreeTable', TreeTable);
app.component('PvOverlayPanel', OverlayPanel);
app.component('PvRating', Rating);
app.component('PvTieredMenu', TieredMenu);

app.directive('tooltip', Tooltip);

const router = createAppRouter();
app.use(router);
app.use(ConfirmationService);

app.mount('#app');
