import { fetchAndCheckJson } from '@/js/dn-fetch.js';

/**
 * @param {number} employeeId
 */
export async function deleteEmployee(employeeId) {
  await fetchAndCheckJson(`employees/${employeeId}`, 'DELETE');
}

/**
 * @param {import("@/model/dn-employee").EmployeeSkillDto[]} data
 */
export async function postEmployeeSkill(data) {
  await fetchAndCheckJson(`employee-skill`, 'POST', data);
}