import { addDays } from "@/js/dn-helper.js";
import { ModelObject } from '@/shared/dn-model-object';

/**
* @typedef {{id?:number; empid:number; st:string|Date; fi:string|Date|null; tasktypeId:number; comment?:string; reasonCode?:string; corrSt?:number; corrFi?:number;userId?:number;updated?:string|Date}} TaskDto
*/

const msPerMinute = 60 * 1000;
const msPerHours = 60 * msPerMinute;
const msPerNormalDay = 24 * msPerHours;

export class Task extends ModelObject {
  /**
   * @param {TaskDto} dto
   */
  constructor(dto) {
    super(dto.id ? dto.id : -1)
    /** @private @type {number} */
    this._empid = dto.empid;
    /** @private @type {Date} */
    this._st = convertToDate(dto.st);
    /** @private @type {Date|null} */
    this._fi = convertToDate(dto.fi);
    /** @type {string} */
    this._comment = convertToString(dto.comment);
    /** @private @type {number} */
    this._tasktypeId = dto.tasktypeId;
    /** @private @type {number} */
    this._corrSt = convertToNumber(dto.corrSt);
    /** @private @type {number} */
    this._corrFi = convertToNumber(dto.corrFi);
    /** @private @type {string|undefined} */
    this._reasonCode = dto.reasonCode;
    /** @private @type {Date} */
    this._updated = dto.updated ? new Date(dto.updated) : null;
    /** @private @type {number} */
    this._userId = dto.userId;

    /** @private @type {Date} */
    this._lastSt = this._st

    /** @private @type {Date|null} */
    this._lastFi = this._fi

    /** @private @type {number} */
    this._logStToggleIndex = 0

    /** @private @type {number} */
    this._logFiToggleIndex = 0
  }

  /**
   * @param {TaskDto} dto
   */
  update(dto) {
    this._id = dto.id;
    this.setOriginalValue('_empid', dto.empid)
    this.setOriginalDateValue('_st', convertToDate(dto.st));
    this.setOriginalDateValue('_fi', convertToDate(dto.fi));
    this.setOriginalValue('_tasktypeId', dto.tasktypeId);
    this.setOriginalValue('_comment', convertToString(dto.comment));
    this.setOriginalValue('_corrSt', convertToNumber(dto.corrSt));
    this.setOriginalValue('_corrFi', convertToNumber(dto.corrFi));
    this._reasonCode = dto.reasonCode ? dto.reasonCode : undefined;
    this._updated = dto.updated ? new Date(dto.updated) : null;
    this._userId = dto.userId;
    this.setHasChanges();
  }

  get updated() {
    return this._updated;
  }

  get userId() {
    return this._userId;
  }

  get empid() {
    return this._empid
  }

  set empid(value) {
    this.setValue('_empid', value);
  }

  /**
   * @return {Date}
   */
  get st() {
    return this._st
  }

  set st(newValue) {
    const oldValue = this._st;
    if (this.setDateValue('_st', newValue)) {
      this._lastSt = oldValue;
    }
  }

  /**
   * @return {Date}
   */
  get fi() {
    if (this._fi === null) {
      const now = new Date();
      if (now.getTime() - this._st.getTime() > msPerNormalDay) {
        this._fi = new Date(this._st.getTime() + msPerNormalDay);
      } else {
        return new Date(msPerMinute * Math.round(now.getTime() / msPerMinute))
      }
    }
    return this._fi;
  }
  set fi(newValue) {
    const oldValue = this._fi;
    if (this.setDateValue('_fi', newValue)) {
      this._lastFi = oldValue;
    }
  }

  get stCorrect() {
    return this.corrSt !== 0 ? new Date(60000 * this.corrSt + this.st.getTime()) : this.st;
  }
  set stCorrect(newValue) {
    this.corrSt = (newValue.getTime() - this.st.getTime()) / 60000
  }

  get fiCorrect() {
    return this.corrFi !== 0 ? new Date(60000 * this.corrFi + this.fi.getTime()) : this.fi;
  }
  set fiCorrect(newValue) {
    this.corrFi = (newValue.getTime() - this.fi.getTime()) / 60000
  }

  get stCorrectTime() {
    return this.st.getTime() + 60000 * this.corrSt;
  }

  get fiCorrectTime() {
    return this.fi.getTime() + 60000 * this.corrFi;
  }

  get tasktypeId() {
    return this._tasktypeId;
  }

  set tasktypeId(newValue) {
    this.setValue('_tasktypeId', newValue);
  }

  get dur() {
    return (this.fi.getTime() - this.st.getTime()) / msPerHours;
  }
  set dur(newValue) {
    this.fi = new Date(msPerHours * newValue + this.st.getTime());
  }
  set durationDate(newValue) {
    const durHours = dateToUnit(newValue)
    this.fi = new Date(msPerHours * durHours + this.st.getTime());
  }
  get durationDate() {
    const durHours = (this.fi.getTime() - this.st.getTime()) / msPerHours;
    return unitToDate(durHours);
  }


  /**
   * @return {Date}
   */
  get lastSt() {
    return this._lastSt;
  }

  /**
   * @return {Date}
   */
  get lastFi() {
    return this._lastFi;
  }

  /**
   * @return {string}
   */
  get comment() {
    return this._comment;
  }

  set comment(newValue) {
    this.setValue('_comment', newValue);
  }

  get reasonCode() {
    return this._reasonCode;
  }

  /**
   * @return {number}
   */
  get corrSt() {
    return this._corrSt;
  }

  set corrSt(newValue) {
    this.setValue('_corrSt', newValue);
  }

  /**
  * @return {number}
  */
  get corrFi() {
    return this._corrFi;
  }

  set corrFi(newValue) {
    this.setValue('_corrFi', newValue);
  }

  /**
   * @return {number}
   */
  get logStToggleIndex() {
    return this._logStToggleIndex
  }

  set logStToggleIndex(newValue) {
    this._logStToggleIndex = newValue
  }

  /**
   * @return {number}
   */
  get logFiToggleIndex() {
    return this._logFiToggleIndex
  }

  set logFiToggleIndex(newValue) {
    this._logFiToggleIndex = newValue
  }

  /**
   * @param {{st:Date, fi:Date}} interval
   */
  contains(interval) {
    return this.stCorrectTime <= interval.st.getTime() && interval.fi.getTime() <= this.fiCorrectTime;
  }

  copy() {
    return new Task(this.toDto());
  }

  adjustToValidCorrFi() {
    /** @type {Date} */
    let newFiDate = undefined;
    if (this.fiCorrectTime < this.stCorrectTime) {
      newFiDate = addDays(this.fiCorrect, 1);
    } else if (addDays(this.stCorrect, 1) < this.fiCorrect) {
      newFiDate = addDays(this.fiCorrect, -1);
    }
    if (newFiDate) {
      const correctionMs = newFiDate.getTime() - this.fi.getTime();
      this.corrFi = correctionMs / 60000;
    }
  }

  /**
   * @param {{st:Date, fi:Date}} interval
   */
  isContainedIn(interval) {
    return !this.isToDelete && this.stCorrectTime >= interval.st.getTime() && interval.fi.getTime() >= this.fiCorrectTime;
  }

  /**
   * @param {Date} st
   * @param {Date} fi
   */
  overlaps(st, fi) {
    return (st.getTime() < this.fiCorrectTime && fi.getTime() > this.stCorrectTime);
  }

  /** @returns {TaskDto} */
  toDto() {
    /** @type {TaskDto} */
    const t = {
      empid: this.empid,
      st: this._st,
      fi: this._fi,
      tasktypeId: this.tasktypeId,
    }

    if (this.id > 0) { t.id = this.id; }
    if (this.corrSt !== 0) { t.corrSt = this.corrSt; }
    if (this.corrFi !== 0) { t.corrFi = this.corrFi; }
    if (this.comment) { t.comment = this.comment; }
    if (this.reasonCode) { t.reasonCode = this.reasonCode; }

    return t;
  }

  adjustLogCorrection() {
    if (this.corrSt !== 0 && this._fi !== null && this._st.getTime() === this._fi.getTime()) {
      const msDay = this.st.getTime();
      const msPerMin = 60 * 1000;
      const st = new Date(msDay + msPerMin * this.corrSt);
      this.st = st;
      this.fi = st;
      this.corrFi = this.corrFi - this.corrSt;
      this.corrSt = 0;
    }
  }
}

export class ScheduleTasks {
  constructor() {
    /** @private @type {boolean} */
    this._hasChanges = false;
    /** @private @type {Task[]} */
    this._list = null;
  }

  get hasChanges() {
    return this._hasChanges;
  }

  get isLoaded() {
    return this._list !== null;
  }

  get list() {
    return this._list;
  }

  /**
   * @param {Task} task
   */
  add(task) {
    task.setContainer(this);
    this.list.push(task);
    this._hasChanges = true;
  }

  clear() {
    this._list = [];
    this._hasChanges = false;
  }

  /**
   * @param {Task} model
   */
  itemChanged(model) {
  }

  /**
   * @param {boolean} hasChanges
   */
  itemHasChanged(hasChanges) {
    if (hasChanges !== this._hasChanges) {
      if (hasChanges) {
        this._hasChanges = true;
      } else {
        this.setItemHasChanges();
      }
    }
  }

  /**
   * @param {Task[]} tasks
   */
  load(tasks) {
    for (const t of tasks) {
      t.setContainer(this)
    }

    this._list = tasks;
    this.setItemHasChanges();
  }

  setItemHasChanges() {
    this._hasChanges = this._list.some(x => x.hasChanges);
  }

  removeUnsavedDeletedTasks(){
    const scheduleTasks = this.list;
    for (let i = scheduleTasks.length - 1; i >= 0; i--) {
      if (scheduleTasks[i].isToDelete && scheduleTasks[i].id == -1) {
        scheduleTasks.splice(i, 1);
      }
    }
  }
}

/**
 * @param {string|null|undefined} text
 */
function convertToString(text) {
  return text ? text : '';
}

/**
 * @param {Date|string|null} dt
 */
function convertToDate(dt) {
  if (dt) {
    return new Date(dt);
  }
  return null;
}

/**
 * @param {number|null|undefined} number
 */
function convertToNumber(number) {
  return number ? number : 0;
}

/**
 * @param {number} unit
 */
function unitToDate(unit) {

  const hours = Math.floor(unit);
  const minutes = (unit - hours) * 60;
  return new Date(2030, 1, 1, hours, minutes);
}

/**
 * @param {Date} time
 */
function dateToUnit(time) {
  return time.getHours() + time.getMinutes() / 60;
}
